// in posts.js
import React from "react";
import {
  List,
  SimpleShowLayout,
  BooleanInput,
  Show,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  Filter,
  BooleanField,
  TextField,
  EditButton,
  ShowButton,
  TextInput,
  required
} from "react-admin";
let devPermission = JSON.parse(localStorage.getItem('devPermission'))
let deleteRow = false
if(devPermission !== null && devPermission.deleteRow === true) {
    deleteRow = devPermission.deleteRow;
}
const validateName = [required()];
const UserGroupFilter = props => (
  <Filter {...props}>
    <TextInput label="Name" source="name" alwaysOn />
  </Filter>
);
const UserGroupName = ({ record }) => {
    return <span>User Groups {record ? `"${record.name}"` : ""}</span>;
};

export const UserGroupList = props => (
  <List filters={<UserGroupFilter />} bulkActions={deleteRow === true ? '' : false} title={<UserGroupName />} {...props}>
    <Datagrid>
      <TextField source="name" />
      <BooleanField source="isActive" />
      <EditButton basePath="/usersgroups" />
      <ShowButton basePath="/usersgroups" />
    </Datagrid>
  </List>
);

export const UserGroupEdit = props => (
  <Edit title={<UserGroupName />} {...props}>
    <SimpleForm  className='user-group-edit ra-input-bordered flexible-card-child column-3 content-inner-pt content-inner-pb'>
      <TextInput source="name" validate={validateName} />
      <BooleanInput label="Active" source="isActive" />
    </SimpleForm>
  </Edit>
);
export const UserGroupShow = props => (
  <Show title={<UserGroupName />} {...props}>
    <SimpleShowLayout className='user-group-show ra-field-column-3 ra-field-bordered flexible-child'>
      <TextField source="id" />
      <TextField source="name" />
      <BooleanField source="isActive" />
    </SimpleShowLayout>
  </Show>
);

export const UserGroupCreate = props => (
  <Create title="Create a User" {...props}>
    <SimpleForm className='user-group-create ra-input-bordered flexible-card-child column-3'>
      <TextInput source="name" validate={validateName} />
      <BooleanInput label="Active" source="isActive" defaultValue={true} />
    </SimpleForm>
  </Create>
);
