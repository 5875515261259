import React, { Component } from 'react';
import {
    FormControl,
    FormControlLabel,
    Button, 
    Select,
    TextField,
    FormLabel,
    RadioGroup,
    Radio,
    Switch
} from '@material-ui/core';
import axios from "axios";
import authHeader from "../_helpers/authHeader";
import SimpleDialogDemo from "./usersPopup";
import './style.css';
import ErrorDialog from "../orders/errorDialog";

class createUser extends Component{
    constructor(props){
        super(props);
        this.state={
            groups: {},
            users: {},
            files: {},
            user: {
                firstname:"",
                lastname:"",
                email:"",
                pdfApproved: false,
                delayedPayment: false,
                isMember: false,
                isVerified: true,
                isActive: true,
                deleted: false,
                groupId: 0,
                selectedUserId: 0,
                parentId: 0,
                phone: null,
                companyName: null,
                parentEmail: "",
                roleId:''
            },
            error: {
                show: false,
                text: ""
            },
            rolesList:{},
            globalError: false,
            globalErrorMessage: [],
            editPage: false,
            isMemberChecked: false,
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeErrorPopup = this.handleChangeErrorPopup.bind(this);
        this.handleTrnSelect = this.handleTrnSelect.bind(this);
        this.handleTradeSelect = this.handleTradeSelect.bind(this);
        this.handleChangeValue = this.handleChangeValue.bind(this);
        this.saveUser = this.saveUser.bind(this);
        this.getRoles = this.getRoles.bind(this);

    }
    async componentDidMount() {
        const getParams = this.props.match.params;
        if(getParams.id){
            this.setState({editPage: true});
            axios.get(`${process.env.REACT_APP_API_URL}/users/${getParams.id}`,
            {
                headers:{Authorization:authHeader().Authorization}
            }).then((response) =>{
                if(Object.keys(response.data).length > 0){ 
                    
                    const data = response.data;
                    const { user } = this.state;
                    user.firstname = data.firstname
                    user.lastname = data.lastname
                    user.email = data.email
                    user.parentId = data.parentId
                    user.selectedUserId = data.parentId
                    user.isMember = data.isMember;
                    user.isVerified = data.isVerified
                    user.isActive = data.isActive
                    user.delayedPayment = data.delayedPayment
                    user.pdfApproved = data.pdfApproved
                    user.parentEmail = data.parentEmail;
                    user.roleId = parseInt(data.roleId,10);
                    user.tradeLicense = data.tradeLicense
                    user.trnCertificate = data.trnCertificate
                    if(data.accountInfo){
                        user.phone = data.phone
                        user.companyName = data.companyName
                        user.deleted = data.deleted
                        user.groupId = data.groupId
                        user.isMember = false
                    }
        
                    this.setState(user)
                }else{
                    window.location.href = `/users`;
                }
            });
        }
        axios.get(`${process.env.REACT_APP_API_URL}/usersgroups?isActive=1`,
        {
            headers:{Authorization:authHeader().Authorization}
        }).then((response) =>{
            if(response.status ===200 && response.data.length > 0){ 
                const { user } = this.state;
                this.setState({groups: response.data})
                if(!user.groupId){
                    user.groupId = response.data[0].id;
                    this.setState(user)
                }
            }
        });
        this.getRoles();
    }

    handleChangeValue(userId) {
        const { user } = this.state;
        user.parentId = userId;
        this.setState(user);
        this.validation();
    }
    handleChangeErrorPopup(val) {
        const {error } = this.state;
        error.show = false;
        this.setState(error)
    }

     getRoles = async () => {
        let apiUrl = process.env.REACT_APP_API_URL
        let requestUrl = `/roles`;
        let currentComponent = this;
        axios.get(apiUrl + requestUrl, {
             headers:{Authorization:authHeader().Authorization}
         }).then(res=>{
            let { data } = res;
            currentComponent.setState({
                rolesList: data
            });
         })
         .catch(error=>{
             console.log(error);
         })
        
     };


     handleChange (event){
        const { target: { name, value , type} } = event;
        const { user , isMemberChecked } = this.state;
        if(name === 'isMember' && event.target.checked) {
            user.groupId = null;
            this.setState({isMemberChecked: true})
        }
        if(type === "checkbox"){
            user[name] = event.target.checked;
        }else if(type === "radio"){
            user[name] = parseInt(value,10);
        }else{
            user[name] = value && value != null ? value : '';
        }
        this.setState(user);
    }
    handleTrnSelect(event){
        const {files} = this.state;
        var trnFile = event.target.files[0];
        files.trnFile=trnFile;
        this.setState(files);
    }
    handleTradeSelect(event){
        const {files} = this.state;
        var tradeFile = event.target.files[0];
        files.tradeFile=tradeFile;
        this.setState(files);
    }
    validation() {  
        const {user, error, editPage} = this.state;
        let code = 200;
        let message = [];
        const keys = ["firstname", "lastname", "email"];
        if(!editPage){
            keys.push("password");
            keys.push("repassword");
        }
        for(var i =0; i< keys.length; i++){
            var key = keys[i]; 
            if(!user[keys[i]] || user[keys[i]] === ""){                
                error[key] = true;
                code = 400;
            }else{
                error[key] = false;
            }
        }

        if(!editPage){
            if(user.password && user.password.length < 6){
                code =  400;
                message.push("Passwords should be content 6 characters");
                this.setState({error: {text: "Passwords should be content 6 characters" ,show: true}});
                return true;
            }
            if(user.password !== user.repassword){
                code = 400;
                message.push("Passwords do not match");
                this.setState({error: {text: "Passwords do not match" ,show: true}});
                console.log(111111,this.state.error);
                return true;

            }
        }
    
        if(user.isMember){
            if(!user.parentId || user.parentId === null){       
                code = 400;
                message.push("Please select parent user for member");
                this.setState({error: {text: "Please select parent user for member" ,show: true}});
                return true;
            }
            if(!user.roleId || user.roleId === null){       
                code = 400;
                message.push("Please select member role");
                this.setState({error: {text: "Please select member role" ,show: true}});
                return true;
            }
        }else{
            if(!user.groupId){
                code = 400;
                message.push("Please select user group");
            }
            if(!user.phone || user.phone === ""){
                code = 400;
                error.phone = true;
            }else{
                error.phone = false;
            }
        }
        console.log({error:error,globalError: true, globalErrorMessage: message})
        if(code != 200){
            this.setState({error:error,globalError: true, globalErrorMessage: message});
            return false
        }
        return true;
    }

    saveUser(){
        console.log('save user');
        if(!this.validation()){
            return false;
        }
        let loader = document.getElementById('loader')
        loader.classList.add('show')
        console.log('save user');
        const {user, files, editPage } = this.state;

        
        const formData = new FormData();
        formData.append('platform','admin')
        if(files){
            if(files.tradeFile){
                formData.append('tradeLicense', files.tradeFile)
            }
            if(files.trnFile){
                formData.append('trnCertificate', files.trnFile)
            }
        }
        formData.append('user',JSON.stringify(user));
        let request = {};
        const getParams = this.props.match.params;
        if(editPage){
           
            request = axios.put(`${process.env.REACT_APP_API_URL}/users/${getParams.id}`,formData,
            {
                headers:{Authorization:authHeader().Authorization}
              })
        }else{
            request = axios.post(`${process.env.REACT_APP_API_URL}/users`,formData,
            {
                headers:{Authorization:authHeader().Authorization}
              })
        }
        request.then((response) =>{
              if(response.data.code ===200){
                const {user} = response.data ;
                if(user && user.id){
                    window.location.href = `/users/${user.id}/show`;
                }else if(editPage){
                    window.location.href = `/users/${getParams.id}/show`;
                }
                
              }
              if(response.data.code === 404 ){
        
                  if(loader.classList.contains('show')) {
                    loader.classList.remove('show')
                  }
                let errMessage = [];
                if(response.data.message.length > 0) {
                    errMessage.push(response.data.message);
                    this.setState({error: {text: response.data.message ,show: true}});
                }else{
                    errMessage.push('Something went wrong. Try again');
                }
                this.setState({globalError: true, globalErrorMessage: errMessage});
              }
      });
    }
    render(){
        const {groups, error,globalError, globalErrorMessage, user,isMemberChecked, editPage, rolesList,files} = this.state;
        let groupsList = groups.length > 0
            && groups.map((item, i) => {
            return (
                <option key={i} value={item.id}>{item.name}</option>
            )
        }, this);
        return (
            <div>
                <div id="loader" className="loader withOpacity circle">
                    <svg
                        width="200px"
                        height="200px"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 100 100"
                        preserveAspectRatio="xMidYMid"
                        className="lds-rolling"
                    >
                        <circle
                            cx="50"
                            cy="50"
                            fill="none"
                            ng-attr-stroke="{{config.color}}"
                            ng-attr-stroke-width="{{config.width}}"
                            ng-attr-r="{{config.radius}}"
                            ng-attr-stroke-dasharray="{{config.dasharray}}"
                            stroke="#B4B000"
                            strokeWidth="2"
                            r="30"
                            strokeDasharray="141.37166941154067 49.12388980384689"
                            transform="rotate(159.346 50 50)"
                        >
                            <animateTransform
                                attributeName="transform"
                                type="rotate"
                                calcMode="linear"
                                values="0 50 50;360 50 50"
                                keyTimes="0;1"
                                dur="1.4s"
                                begin="0s"
                                repeatCount="indefinite"
                            ></animateTransform>
                        </circle>
                    </svg>
                </div>
                <FormControl className={`column-3 flexible-child ${editPage ? 'user-edit' : 'user-create'}`}>
                  {/*  {
                        globalError ?
                            <div style={{color: "red", fontSize:18, textAlign:"center"}}>{
                                globalErrorMessage.map((message,key)=>{
                                    return <span style={{clear:"both",float:"left",width:"100%"}} key={key}>{message}</span>
                                })} </div>
                            :
                            ""
                    }*/}

                    <ErrorDialog open={this.state.error.show} text={this.state.error.text} onChangeValue = {this.handleChangeErrorPopup} />

                    <TextField
                        id="firstname"
                        label="First name"
                        name="firstname"
                        type="text"
                        required="true"
                        value={user.firstname}
                        onChange={this.handleChange}
                        margin="normal"
                        error = {error.firstname}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        className="item"
                    />
                    <TextField
                        id="lastname"
                        label="Last name"
                        name="lastname"
                        value={user.lastname}
                        type="text"
                        required="true"
                        onChange={this.handleChange}
                        margin="normal"
                        error = {error.lastname}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        className="item"
                    />
                    <TextField
                        id="email"
                        label="Email"
                        name="email"
                        type="email"
                        value={user.email}
                        required="true"
                        onChange={this.handleChange}
                        margin="normal"
                        error = {error.email}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        className="item"
                    />
                    {
                        !editPage ?
                            <div className='w-third'>
                                <TextField
                                    id="password"
                                    label="Password"
                                    name="password"
                                    value={user.password}
                                    type="password"
                                    required="true"
                                    onChange={this.handleChange}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error = {error.password}
                                    className="item"
                                />
                                <TextField
                                    id="repassword"
                                    label="Confirm password"
                                    value={user.repassword}
                                    name="repassword"
                                    type="password"
                                    required="true"
                                    onChange={this.handleChange}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error = {error.repassword}
                                    className="item"
                                />
                                {/*{globalErrorMessage[0] &&
                                    <span className="error-msg">
                                            {globalErrorMessage[0]}
                                    </span>
                                }*/}
                            </div>

                            : ""
                    }

                    {
                        !editPage ?
                            <FormControlLabel
                                control={<Switch size="small" value="isMember" checked={user.isMember} onChange={this.handleChange} />}
                                label="Is member"
                                name="isMember"
                                className="item"
                            />
                            :
                            ""

                    }

                    {
                        user.isMember ?
                            (
                                <React.Fragment>
                                    {
                                        rolesList.length > 0 ?
                                            <div className='box-bordered'>
                                                <FormControl component="fieldset">
                                                    <FormLabel component="legend">Roles </FormLabel>
                                                    <RadioGroup aria-label="role" name="roleId" value={user.roleId} onChange={this.handleChange}>
                                                        { rolesList.map((item, key) => {
                                                            return (
                                                                <FormControlLabel value={item.id} key={item.id} control={<Radio />} label={item.name} />
                                                            )
                                                        })
                                                        }
                                                    </RadioGroup>
                                                </FormControl>
                                            </div>
                                            :('')
                                    }
                                    {isMemberChecked && <div className='box-bordered'>
                                                         <SimpleDialogDemo title="Select Parent"
                                                          error={error.memberUser}
                                                          selectedValue={user.parentEmail}
                                                          userId={user.parentId}
                                                          onChangeValue={this.handleChangeValue}

                                                         />
                                                             </div>}
                                </React.Fragment>
                            )

                            :
                            <div className="user-info flexible-child column-3">
                                <Select
                                    name="groupId"
                                    native
                                    value={user.groupId}
                                    onChange={this.handleChange}
                                    className='item select-item'
                                >
                                    {groupsList}
                                </Select>
                                <TextField
                                    id="phone"
                                    label="Phone"
                                    name="phone"
                                    type="text"
                                    value={user.phone}
                                    required="true"
                                    onChange={this.handleChange}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error = {error.phone}
                                    className='item'
                                />
                                <TextField
                                    id="companyName"
                                    label="Company Name"
                                    name="companyName"
                                    type="text"
                                    value={user.companyName}
                                    required="false"
                                    onChange={this.handleChange}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error = {error.companyName}
                                    className='item'
                                />
                            </div>
                    }
                    <div className='box-bordered d-flex flex-wrap flex-column'>
                        {
                            editPage && <FormControlLabel
                                control={<Switch size="small" value="deleted" checked={user.deleted} onChange={this.handleChange} />}
                                label="Deleted"
                                name="deleted"
                            />
                        }
                        <FormControlLabel
                            control={<Switch size="small" value="isVerified" checked={user.isVerified} onChange={this.handleChange} />}
                            label="Verified"
                            name="isVerified"
                            onChange={this.handleChange}
                        />
                        <FormControlLabel
                            control={<Switch size="small" value="isActive" checked={user.isActive} onChange={this.handleChange} />}
                            label="Active"
                            name="isActive"
                        />
                        <FormControlLabel
                            control={<Switch size="small" value="delayedPayment" checked={user.delayedPayment} onChange={this.handleChange} />}
                            label="Delayed Payment"
                            name="delayedPayment"
                        />
                        <FormControlLabel
                            control={<Switch size="small" value="pdfApproved" checked={user.pdfApproved} onChange={this.handleChange} />}
                            label="pdfApproved"
                            name="pdfApproved"
                        />
                    </div>
                    <div className='upload-files'>
                        <FormControl>
                            {user.trnCertificate ? (
                                <a href={user.trnCertificate} target="_blank" className="file-link">TRN certificate</a>
                            ): (
                                <label className='file-label'>
                                    <input
                                        accept="application/pdf, image/*"
                                        id="file1"
                                        name="file1"
                                        type="file"
                                        margin="normal"
                                        onChange={this.handleTrnSelect}
                                        style={{display:"none"}}
                                    />
                                    {files.trnFile ? files.trnFile.name:('Upload TRN certificate file')}

                                </label>
                            )}

                        </FormControl>
                        <FormControl>
                            {user.tradeLicense ? (
                                <a href={user.tradeLicense} target="_blank" className="file-link">Trade License</a>
                            ): (
                                <label className='file-label'>
                                    <input
                                        accept="application/pdf, image/*"
                                        id="file2"
                                        name="file2"
                                        onChange={this.handleTradeSelect}
                                        type="file"
                                        style={{display:"none"}}
                                    />
                                    {files.tradeFile ? files.tradeFile.name:('Upload Trade License file')}
                                </label>
                            )}
                        </FormControl>
                    </div>
                    <Button color="primary" className='custom-create-btn' variant="contained"
                            onClick = {this.saveUser}>Save</Button>
                </FormControl>
            </div>
        );
    }
 }

 export default createUser;