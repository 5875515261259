import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {Table, TableBody, TableCell, TableRow} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import TablePagination from '@material-ui/core/TablePagination';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';

import axios from "axios";
import authHeader from "../_helpers/authHeader";
const useStyles = makeStyles(theme => ({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));
function SimpleDialog(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open, userId,orderId } = props;
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  var [page, setPage] = React.useState(0);
  var [users, setUsers] = React.useState({});
  var [usersCount, setUsersCount] = React.useState(0);
  var [filterEmail, setFilterEmail] = React.useState('');
  var [accountInfo, setAccountInfo] = React.useState({});
  var [parentId, setParentId] = React.useState(props.parentId);
  const prevFilterEmail = useRef();

  React.useEffect( () => {
      let query = {};
      if(filterEmail !== ""){
          query.email = filterEmail;
      }
      if(filterEmail !== prevFilterEmail.current){
          setPage(0);
      }
      prevFilterEmail.current = filterEmail;
      let limit = 10;
      let startNumber = page*limit;                                
      let endNumber = page*limit + limit;               
      query._start = startNumber
      query._end = endNumber
      query._order =  "DESC"
      query._sort = "id"

      query.parentId = 0;
      if(parentId){
        query.parentId = props.parentId;
      }     
      const fetchData = async () => {
        const result =  await axios.get(`${process.env.REACT_APP_API_URL}/users`,
        {
            headers:{Authorization:authHeader().Authorization},
            params: query
        }).then((response) =>{
            if(response.status === 200 && response.data.length > 0){
                setUsers(response.data)
                let count = response.headers['x-total-count'];
                setUsersCount(parseInt(count));
                return response;
            }else{
                setUsers({})
                setUsersCount(0);
            }
            });

        }
      fetchData();   
    }, [page, filterEmail, props.parentId]);


  React.useEffect( () => {
    props.onChangeValue(userId, selectedValue, accountInfo);
    
  },
   [props.userId, props.selectedValue])


    const handleChangePage =  (event, newPage) => {
        setPage(newPage);
    }
  const handleClose = () => {

    onClose(selectedValue, userId);
  };

  const handleListItemClick = (email, userId, accountInfo) => {
    setAccountInfo(accountInfo)
    onClose(email, userId, accountInfo);
  };
  const filterChange = (event, value) => {
      setFilterEmail(event.target.value);
  }
  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Select user</DialogTitle>
      
      <List>
        {(users.length > 0) ? 
        <div>
        <div>
          <TextField
            id="outlined-basic"
            className={classes.textField}
            label="Email"
            margin="normal"
            variant="outlined"
            value={filterEmail}
            onChange={filterChange}
          />
        </div>
      
        <Table className={classes.table} aria-label="simple table">
          <TableBody>
    
            {users.map(function(user) {
              return (
              <TableRow key={user.id} onClick={() => handleListItemClick(user.email, user.id, user.accountInfo)} key={user.id}>
                <TableCell>
                  <Avatar className={classes.avatar}>
                    <PersonIcon />
                  </Avatar>
                </TableCell>
                <TableCell align="right">{user.id}</TableCell>
                <TableCell align="right">{user.firstname}</TableCell>
                <TableCell align="right">{user.email}</TableCell>

              </TableRow>
              );
            })}
          </TableBody>
        </Table>
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count = {usersCount}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onChangePage={handleChangePage}
          />
          </div>
          :
            <div>
                <div>
                    <TextField
                        id="outlined-basic"
                        className={classes.textField}
                        label="Email"
                        margin="normal"
                        variant="outlined"
                        value={filterEmail}
                        onChange={filterChange}
                    />
                </div>
                Users not exist
            </div>


        }

      </List>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  //selectedValue: PropTypes.string.isRequired,
  selectedValue: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.object.isRequired
  ]),
};

export default function SimpleDialogDemo(props) {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(props.selectedValue);
  const [userId, setUserId] = React.useState(props.userId);
  const [parentId, setParentId] = React.useState(props.parentId);

  const handleClickOpen = () => {
    setOpen(true);
  };
  React.useEffect( () => {
      // setSelectedValue('');
  },
   [props.parentId])

  React.useEffect( () => {
          if(props.selectedValue && props.selectedValue !=''){
              setSelectedValue(props.selectedValue);
          }
          if(props.selectedValue && props.userId !=''){
              setUserId(props.userId);
          }
      },
      [props.selectedValue, props.userId]);

    //Hooks
    React.useEffect(() => {
        console.log('count changed', props.count);
    }, [props.count])



    const handleClose = (email, userIdVal) => {
    setOpen(false);
    setUserId(userIdVal);
    setSelectedValue(email);
    setParentId(parentId)

  };
  return (
    <div>
      <div variant="subtitle1" className="text-break" >
          {(props.showSelected != undefined && !props.showSelected) ? '' : selectedValue == '' ? <span className='color-light'>"Select a user"</span> : JSON.stringify(selectedValue)}</div>
      <br />
      {props.orderId ? (''):(
        <Button variant="outlined" color="primary" onClick={handleClickOpen} disabled={props.isDisabled}>
        {props.title}
        </Button>
      )}
     
      <SimpleDialog selectedValue={selectedValue} parentId={props.parentId} userId={userId} onChangeValue={props.onChangeValue}  open={open} onClose={handleClose} />
    </div>
  );
}