import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import React, { Component } from 'react';
import {TextField,FormControl,FormControlLabel,Switch,Button} from '@material-ui/core';
import OptionChildren from "./OptionChildren";
import ConfirmDialog from "./confirmDialog"

import './style.css'
class OptionItemRowItem extends Component {
    constructor(props){
        super(props);
        this.state = {
            options:props.options,
            field:props.field,
            useStyles:props.useStyles,
            typeId:props.typeId,
            confirmShow:false
        }  

        if(props.field && props.field.id && !props.field.fieldId){
            this.state.field['fieldId'] = props.field.id;
        } 
        this.handleChange = this.handleChange.bind(this);
        this.handleFileSelect = this.handleFileSelect.bind(this);
        this.imageconvertFileToBase64 = this.imageconvertFileToBase64.bind(this);
        this.deleteOptionField = this.deleteOptionField.bind(this);
        this.setOptionFieldChildren = this.setOptionFieldChildren.bind(this);
        this.handleChangeConfirmPopup = this.handleChangeConfirmPopup.bind(this);
        this.handleHideConfirmPopup = this.handleHideConfirmPopup.bind(this);
    }
    setOptionFieldChildren(children){
        const {field} = this.state;

        field.children = children;
        //this.props.changeValue(option);
    }
    componentWillReceiveProps(props){
        const {field,options} = props;
        if(props.field && props.field.id && !props.field.fieldId){
            this.state.field['fieldId'] = props.field.id;
        } 
        if(!field.image){
            const key = field.fieldId ? field.fieldId :field.id;
            document.getElementById(`contained-button-file-${key}`).value = "";
        }
        this.setState({field,options});
    }
    deleteOptionField(event){
        // const {field} = this.state;
        // this.props.deleteOptionField(field.fieldId);

        const {confirmShow} = this.state;
        this.setState({
            confirmShow:true
        })
    }
    
    handleChange(event){
        const {field} = this.state;
        const { target: { name, value , type} } = event;
        field[name] = value;

        if(type === 'checkbox'){
            field[name] =  event.target.checked
        }
        this.setState(field);
    }
    imageconvertFileToBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.createObjectURL(file);
        URL.createObjectURL(file)

        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });
    handleFileSelect(event){
        const {field} = this.state;
        var file = event.target.files[0];
        field.image=file;
        this.setState(field);
    }

    handleHideConfirmPopup() {
        const {confirmShow} = this.state;
        this.setState({
            confirmShow:false
        })
    }
    handleChangeConfirmPopup(val) {
        const {confirmShow} = this.state;
        this.setState({
            confirmShow:false
        })

        const {field} = this.state;
        this.props.deleteOptionField(field.fieldId);
    }
    render(){
        const {field,useStyles,options,typeId} = this.state;
        const key = field.fieldId ? field.fieldId :  field.id;

        return (   
               <div key={key} className='border-bottom-bold'>
                <ConfirmDialog open={this.state.confirmShow} text={'Are you sure?'} onHandleClose={this.handleHideConfirmPopup} onChangeValue = {this.handleChangeConfirmPopup} />

                <div className='option-items flexible-child column-4'>
                    <FormControl style={useStyles.formControl}>
                        <TextField
                            id="rowtitle"
                            label="Title"
                            className={useStyles.textField}
                            value={field.title}
                            name="title"
                            onChange={this.handleChange}
                            margin="normal"
                        />
                    </FormControl>
                    <FormControl style={useStyles.formControlSmall}>
                        <TextField
                            id="rowprice"
                            label="Price"
                            className={useStyles.textField}
                            value={field.price}
                            name="price"
                            onChange={this.handleChange}
                            margin="normal"
                            inputProps={{ min: "0",  step: "1" }}
                            type="number"
                        />
                    </FormControl>
                    <FormControl style={useStyles.formControlSmall}>
                        <TextField
                            id="rowsortOrder"
                            label="Sort Order"
                            className={useStyles.textField}
                            value={field.sortOrder}
                            name="sortOrder"
                            onChange={this.handleChange}
                            margin="normal"
                            inputProps={{ min: "0",  step: "1" }}
                            type="number"
                        />
                    </FormControl>
                    <FormControl style={useStyles.formControl}>
                        <OptionChildren options={options} setOptionFieldChildren={this.setOptionFieldChildren} currField={field} />
                    </FormControl>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={field.isDefault ? true : false}
                                onChange={this.handleChange}
                                name="isDefault"
                                color="primary"
                            />
                        }
                        label="Is Default"
                    />

                    <div className='text-right'>
                        {field.image &&
                            <span className='uploaded-img-name'>
                                {field.image.name}
                            </span>
                        }
                        <label className='upload-label btn'> Upload File
                            <input
                                accept="image/*,application/pdf"
                                className={useStyles.textField}
                                id={`contained-button-file-${key}`}
                                onChange={this.handleFileSelect}
                                type="file"
                            />
                        </label>
                    </div>
                    <TextField
                        id="skuOrder"
                        label="SKU"
                        className='custom-label-child'
                        value={field.sku}
                        name="sku"
                        onChange={this.handleChange}
                        margin="normal"
                        type="text"
                    />
                    
                    {typeId === 11 ? 
                        <TextField
                            id="downloadableDesc"
                        label="File Title"
                        className='custom-label-child'
                        value={field.downloadableDescription}
                        name="downloadableDescription"
                        onChange={this.handleChange}
                        margin="normal"
                        type="text"
                    /> :
                        ''    
                    }
                    {
                        field.uploadedImage && field.uploadedImage  !== '' ?
                            <img src={`${process.env.REACT_APP_IMAGE_URL}${field.uploadedImage}` } alt="" width="120px"/>
                            :
                            ''
                    }
                    {
                        field.file != null && Object.keys(field.file).length ?
                           <a href={`${process.env.REACT_APP_IMAGE_URL}${field.file.file}` } target="_blank" alt="" width="120px" > See File </a>
                            :
                            ''
                    }
                </div>
               <Button onClick={this.deleteOptionField} variant="outlined" color="secondary">  <DeleteForeverIcon /> Delete</Button>
               </div>
        )
    }
}
export default OptionItemRowItem

