import React, { Component } from 'react';

import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import { withStyles } from "@material-ui/core/styles";
import { Button, Link } from "react-admin";

const styles = {
  button: {
    marginTop: "1em",
  },
};


const AddNewCreditsButton = (props) => (
    <Button
        className={props.classes.button}
        variant="raised"
        component={Link}
        to={{
            pathname: "/credits/create",
            search: `?userId=${props.record.id}`,
        }}
        label="Add New Credit"
        disabled={props.isDisabled}
    >
        <ChatBubbleIcon />
    </Button>
);
export default withStyles(styles)(AddNewCreditsButton);
