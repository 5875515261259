// in posts.js
import React from "react";
import './style.css';
import {
  List,
  Show,
  SimpleShowLayout,
  Datagrid,
  DateField,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  ShowButton,
  DisabledInput,
  TextInput,
  ImageField,
  ImageInput,
  NumberInput,
  SelectInput,
  LongTextInput,
  RichTextField,
  Filter,
  required, ShowController, Tab
} from "react-admin";

const validateTitle = [required()];
const validateDescription = [required()];
const validateBtnText = [required()];
const validateUrl = [required()];
const validateType = [required()];
const validateFile = [required()];
const BannersFilter = props => (
  <Filter {...props}>
    <TextInput label="Url" source="url" alwaysOn />
    <TextInput label="Title" source="title" />
    <TextInput label="Description" source="description" />
    <TextInput label="Application Type" source="application_type" />
  </Filter>
);
export const BannersList = props => (
  <List filters={<BannersFilter />} {...props}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="title" />
      <TextField source="url" />
      <TextField source="application_type" />
      <EditButton basePath="/banners" />
      <ShowButton basePath="/banners" />
    </Datagrid>
  </List>
);

const BannerTitle = ({ record }) => {
  return <span>Banner {record ? `"${record.title}"` : ""}</span>;
};
function formatLogo(value) {
  if (!value || typeof value === "string") {
    // Value is null or the url string from the backend, wrap it in an object so the form input can handle it
    return { url: value };
  } else {
    // Else a new image is selected which results in a value object already having a preview link under the url key
    return value;
  }
}

export const BannersEdit = props => (
  <Edit title={<BannerTitle />} {...props}>
    <SimpleForm className="banner-edit ra-input-bordered flexible-card-child content-inner-pt content-inner-pb column-3">
      <DisabledInput source="id" />
      <TextInput source="title" validate={validateTitle} />
      <LongTextInput source="description" validate={validateDescription} />
      <TextInput
        source="btn_text"
        title="Button Text"
      />
      <TextInput source="url" />
      <NumberInput source="promotion" />
      <SelectInput
        source="application_type"
        validate={validateType}
        choices={[
          { id: "web", name: "Web" },
          { id: "mobile", name: "Mobile" }
        ]}
      />
      <DisabledInput source="createdAt" />
      <ImageInput
          format={formatLogo}
          label="Upload"
          source="file"
          accept="image/*"
          className="upload-field"
      >
         <ImageField source="file" />
      </ImageInput>
      <ImageField source="file" src="file" className="banner-file"/>
    </SimpleForm>
  </Edit>
);

export const BannersCreate = props => (
  <Create title="Create Banner" {...props}>
    <SimpleForm encType="multipart/form-data" className="banner-create ra-input-bordered flexible-card-child column-3">
      <TextInput source="title" validate={validateTitle} />
      <LongTextInput source="description" validate={validateDescription} />
      <TextInput
        source="btn_text"
        title="Button Text"
      />
      <TextInput source="url"  />
      <NumberInput source="promotion" />
      <SelectInput
        source="application_type"
        validate={validateType}
        choices={[
          { id: "web", name: "Web" },
          { id: "mobile", name: "Mobile" }
        ]}
      />
      <ImageInput
          validate={validateFile}
          source="file" accept="image/*"
          className="upload-field">
        <ImageField source="file" />
      </ImageInput>
    </SimpleForm>
  </Create>
);

export const BannersShow = props => (
    <ShowController {...props}>
      {controllerProps =>
          <Show title={<BannerTitle />} {...props} {...controllerProps}>
            <SimpleShowLayout className="banner-show ra-field-bordered flexible-child ra-field-column-3 content-inner-pt">
              <TextField source="id"/>
              <TextField source="title"/>
              <RichTextField source="description"/>
              <TextField source="btn_text"/>
              <TextField source="url"/>
              {controllerProps.record && controllerProps.record.promotion &&
                <TextField source="promotion"/>
              }
              <TextField source="application_type"/>
              <DateField label="Publication date" source="createdAt"/>
              <ImageField source="file" className="banner-file"/>
            </SimpleShowLayout>
          </Show>
      }
    </ShowController>
);
