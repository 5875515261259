import React from 'react';
import Radio from '@material-ui/core/Radio';
import { makeStyles } from '@material-ui/core/styles';
import RadioGroup from '@material-ui/core/RadioGroup';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import axios from "axios";
import authHeader from "../_helpers/authHeader";
import { FormGroup } from '@material-ui/core';
import Input from '@material-ui/core/Input';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: "100%"
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function FormControlLabelPosition(props) {
  const classes = useStyles();
  const { value , onChangeValue, orderId, update , productType,colorsObj} = props;
  const [orderTypePrices, setOrderTypePrices] = React.useState(value);
  const [byColors, setByColors] = React.useState({});

    React.useEffect( () => {
      // if(update){
      axios.get(`${process.env.REACT_APP_API_URL}/ordertypeprices/${orderId}`,
      {
          headers:{Authorization:authHeader().Authorization},
      }).then((response) =>{
        var i,dataLength = 0, dataValues = {};
        let  byColorsObj = {};
        if(response.status === 200) {
        for (i in response.data) {
          if (response.data.hasOwnProperty(i)) {
            dataLength++;
            dataValues[i] = Number.parseFloat(response.data[i]).toFixed(2);
          }
        }
        if(response.data.hasOwnProperty('byColors')){
          byColorsObj = response.data.byColors
          setByColors(byColorsObj);
          if(Object.keys(byColorsObj).length > 0) {
            colorsObj(byColorsObj)
          }
        }

        if(dataLength>0){
          setOrderTypePrices(dataValues);
          onChangeValue(dataValues)
         }
        }
      });
    // }
    }, []);
  return(
      <FormControl disabled={props.disabled} component="fieldset" className={`${classes.formControl} displayBlock`} onChange={onChangeValue}>
      <FormLabel component="legend">ORDER TYPE PRICES</FormLabel>
        <div className="flex-box">
          {productType !== 'fingerprint' ? (
            <React.Fragment>
              
              <div>  
                <label>Pdf Price</label><br></br>
                <Input type="number" value={orderTypePrices.pdfPrice} name='pdfPrice' step='0.01' />
              </div>
              <div>  
                <label>Pdf Price Discount</label><br></br>
                <Input type="number" value={orderTypePrices['pdfPriceDiscount']} name='pdfPriceDiscount' step='0.01' />
              </div>
              {Object.keys(byColors).length === 0 ?
                (
                  <>
                    <div>  
                      <label>Physical Plate ( Price Per Color )</label><br></br>
                      <Input type="number" value={orderTypePrices.physicalPrice} name='physicalPrice' step='0.01' />
                    </div>
                    <div>  
                      <label>Physical Price Discount</label><br></br>
                      <Input type="number" value={orderTypePrices['physicalPriceDiscount']} name='physicalPriceDiscount' step='0.01' />
                    </div>

                    <div>  
                      <label>Len Price</label><br></br>
                      <Input type="number" value={orderTypePrices.lenPrice} step="0.01" name='lenPrice' />
                    </div>
                    <div>  
                      <label>Len Price Discount</label><br></br>
                      <Input type="number" value={orderTypePrices['lenPriceDiscount']} name='lenPriceDiscount' step='0.01' />
                    </div>
                  </>
                ):('')
              }

              <div>
                <label>Proofing Price</label><br></br>
                <Input type="number" value={orderTypePrices.proofingPrice} step="0.01" name='proofingPrice' />
                </div>
              <div>
                <label>Proofing Price Discount</label><br></br>
                <Input type="number" value={orderTypePrices['proofingPriceDiscount']} name='proofingPriceDiscount' step='0.01' />
              </div>
            </React.Fragment>
          ):(
            <React.Fragment>
              <div>  
                <label>Physical Price</label><br></br>
                <Input type="number" value={orderTypePrices.physicalPrice} name='physicalPrice' step='0.01' />
              </div>
              <div>  
                <label>Physical Price Discount</label><br></br>
                <Input type="number" value={orderTypePrices['physicalPriceDiscount']} name='physicalPriceDiscount' step='0.01' />
              </div>
              <div>
                <label>Proofing Price</label><br></br>
                <Input type="number" value={orderTypePrices.proofingPrice} step="0.01" name='proofingPrice' />
              </div>
              <div>
                <label>Proofing Price Discount</label><br></br>
                <Input type="number" value={orderTypePrices['proofingPriceDiscount']} name='proofingPriceDiscount' step='0.01' />
              </div>
            </React.Fragment>
          )}
        </div>
    </FormControl>
  );
  
}


