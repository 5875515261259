// in posts.js
import React from 'react';
import { 
    List, 
    Show,
    SimpleShowLayout,
    Datagrid, 
    DateField,
    Edit, 
    Create, 
    SimpleForm, 
    TextField,
    EditButton,
    ShowButton,
    DisabledInput,
    ImageInput,
    ImageField,
    TextInput,
    NumberInput,
    SelectInput,
    BooleanInput,
    SaveButton,
    RadioButtonGroupInput,
    required
 } from 'react-admin';
//import BookIcon from '@material-ui/core/svg-icons/action/book';
//export const PostIcon = BookIcon;


export const GalleriesList = (props) => (
    <List  {...props}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="category_id" />
            <TextField source="file" />
            <TextField source="status" />
            <EditButton basePath="/categories" />
            <ShowButton basePath="/categories" />
        </Datagrid>
    </List>
);

// redirect to the related categories edit page
const redirect = (basePath, id, data) => `/categories/${data.category_id}`;

const CategoryGalleryName = ({ record }) => {
    return <span>Category Galleries {record ? `"${record.id}"`: ''}</span>;
};

export const CategoryGalleriesEdit = (props) => (
    <Edit title={<CategoryGalleryName />} {...props} >
        <SimpleForm redirect={redirect} className='ra-input-column-3 flexible-card-child ra-input-bordered'>
            <DisabledInput source="id" />
            <SelectInput source="status"  choices={[
                { id: 'active', name: 'Active' },
                { id: 'inactive', name: 'Inactive' },
            ]} />
            <NumberInput source="sort_order"  />
            <ImageField source="file"  src="file"/>
            <RadioButtonGroupInput source="GalleryType" choices={[
                { id: 'None', name: 'None' },
                { id: 'ForFeatured', name: 'For Featured' },
                { id: 'ForThumbnail', name: 'For Thumbnail' },
                { id: 'ForMobileThumbnail', name: 'For Mobile Thumbnail' },
            ]} />
        </SimpleForm>
    </Edit>
);



