import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: "100%"
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
export default function SendEmailCheckbox(props) {
  const classes = useStyles();
  const { value , onChangeValue } = props;
  return (
    <FormControl component="fieldset" className={classes.formControl} disabled={props.isDisabled}>
      {/* <FormLabel component="legend">Email order confirmation</FormLabel> */}
      <FormControlLabel
        control={
          <Checkbox
            checked={value}
            onChange={onChangeValue}
            value={value}
            color="primary"
          />
        }
        label="Email order confirmation"
      />
    </FormControl>
  );
}


