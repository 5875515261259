import React, { Component } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class Types extends Component {

    constructor(props){
        super(props);
        this.state = {
            types:props.types,
            useStyles:props.useStyles,
            selectedType:props.typeId
        }   
        this.handleChange = this.handleChange.bind(this);
    }
    componentWillReceiveProps(props){
        const {types,type} =  props;
        this.setState({types,type})
    }
    handleChange(event){
        const {types} = this.state;
        const index = types.findIndex(x=>x.id === event.target.value);
        if(index !== -1){
            this.setState({selectedType:event.target.value});
            this.props.setType(types[index]);
        }
        
    }
    render(){
        const {types,selectedType, useStyles} = this.state;
        if(!types || types.length === 0) return <div>No Types</div>
        return (   
            <div>
                <FormControl style={useStyles.formControl}>
                <InputLabel htmlFor="select-multiple-checkbox">Types</InputLabel>
                    <Select
                    value={selectedType}
                    onChange={this.handleChange}
                    MenuProps={MenuProps}
                    >
                    {types.map(type => (
                       
                        <MenuItem key={type.id} value={type.id}>
                            <ListItemText primary={type.label} />
                        </MenuItem>
                    ))}
                    </Select>
                </FormControl>
            </div>
        )
    }
}
export default Types