import React from 'react';
import './style.css';
import {
    List,
    Datagrid,
    Edit,
    Create,
    SimpleForm,
    Filter,
    TextField,
    EditButton,
    DisabledInput,
    TextInput,
    required,
    minLength, FormTab,
} from 'react-admin';
import RichTextInput from "ra-input-rich-text";
 
const validateName = [required(), minLength(2)];
const validateText = [required(), minLength(2)];

const CompFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="name" alwaysOn />
    </Filter>
);

const TestimonialTitle = ({ record }) => {
    return <span>Testimonial {record ? `"${record.name}"` : ""}</span>;
};

export const TestimonialsList = (props) => (
    <List filters={<CompFilter />} {...props}>
        <Datagrid className='testimonials-list'>
            <TextField source="name" />
            <TextField source="text" />
            <EditButton basePath="/testimonials" />
        </Datagrid>
    </List>
);

export const TestimonialsEdit = (props) => (
    <Edit title={<TestimonialTitle />} {...props}>
        <SimpleForm className='testimonials-edit ra-input-bordered flexible-mui-child content-inner-pt content-inner-pb column-3'>
            <DisabledInput source="id" />
            <TextInput source="name" validate={validateName}/>
            <RichTextInput source="text" className="description-text" validate={validateText}/>
            <DisabledInput  source="createdAt" />
            <DisabledInput  source="updatedAt" />
        </SimpleForm>
    </Edit>
);

export const TestimonialsCreate = (props) => (
    <Create title="Create a Testimonial" {...props}>
        <SimpleForm className='testimonials-create ra-input-bordered flexible-mui-child column-3 content-inner-pb'>
            <TextInput source="name" validate={validateName}/>
            <RichTextInput source="text" className="description-text" validate={validateText} />
        </SimpleForm>
    </Create>
);