import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {Table, TableBody, TableCell, TableRow} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import TablePagination from '@material-ui/core/TablePagination';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';

import axios from "axios";
import authHeader from "../_helpers/authHeader";
const useStyles = makeStyles(theme => ({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));
function SimpleDialog(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open, adminId } = props;
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  var [page, setPage] = React.useState(0);
  var [admins, setAdmins] = React.useState({});
  var [adminsCount, setAdminsCount] = React.useState(0);
  var [filterEmail, setFilterEmail] = React.useState('');
    const prevFilterEmail = useRef();

    React.useEffect( () => {
        let query = {};
        if(filterEmail !== ""){
            query.email = filterEmail;
        }
        if(filterEmail !== prevFilterEmail.current){
            setPage(0);
        }
        prevFilterEmail.current = filterEmail;
        let limit = 10;
        let startNumber = page*limit;
        let endNumber = page*limit + limit;
        query._start = startNumber
        query._end = endNumber
        query._order =  "DESC"
        query._sort = "id"

      const fetchData = async () => {
         await axios.get(`${process.env.REACT_APP_API_URL}/adminusers`,
        {
            headers:{Authorization:authHeader().Authorization},
            params: query
        }).then((response) =>{
            if(response.status === 200 && response.data.length > 0){
                setAdmins(response.data)
                let count = response.headers['x-total-count'];
                setAdminsCount(parseInt(count));
                return response;
            }else{
              setAdmins({})
              setAdminsCount(0);
            }
            });
            
        }
    fetchData();   
        // console.log('users ', admins);
    }, [page, filterEmail]);


  React.useEffect( () => {
    props.onChangeValue(adminId, selectedValue);
  },
   [adminId, selectedValue])


    const handleChangePage =  (event, newPage) => {
        setPage(newPage);
    }
    const handleClose = () => {
        onClose(selectedValue, adminId);
        setFilterEmail('')
    };

  const handleListItemClick = (email, adminId) => {
    onClose(email, adminId);
  };
  const filterChange = (event, value) => {
      setFilterEmail(event.target.value);
  }
  
  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} className="custom-style">
      <DialogTitle id="simple-dialog-title">Select user</DialogTitle>
            <List>
                    <div>
                        <div>
        <TextField
          id="outlined-basic"
          className={classes.textField}
          label="Email"
          margin="normal"
          variant="outlined"
          value={filterEmail}
          onChange={filterChange}
        />
      </div>
   {(admins.length > 0) ?
      <Table className={classes.table} aria-label="simple table">
          <TableBody>
              {admins.map(function(admin) {
                  return (
                      <TableRow className="pointer" onClick={() => handleListItemClick(admin.email, admin.id)} key={admin.id}>

                          <TableCell>
                              <Avatar className={classes.avatar}>
                                  <PersonIcon />
                              </Avatar>
                          </TableCell>
                          <TableCell align="right">{admin.id}</TableCell>
                          <TableCell align="right">{admin.firstname}</TableCell>
                          <TableCell align="right">{admin.email}</TableCell>
                      </TableRow>
                  );
              })}
          </TableBody>
        </Table> : <p className="mt-4 ml-2 color-secondary">Admins not exist</p>}
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count = {adminsCount}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onChangePage={handleChangePage}
         />
        </div>
            </List>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function SimpleDialogDemo(props) {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(props.selectedValue);
  const [adminId, setAdminId] = React.useState(props.adminId);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (email, adminIdVal) => {
    setOpen(false);
    setAdminId(adminIdVal);
    setSelectedValue(email);
  };
 
  return (
    <div>
      <div variant="subtitle1"  >{(props.showSelected != undefined && !props.showSelected) ? "" : selectedValue}</div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen} className="mt-2" disabled={props.isDisabled}>
        {props.title}
      </Button>
      <SimpleDialog selectedValue={selectedValue} adminId={adminId} onChangeValue={props.onChangeValue}  open={open} onClose={handleClose} />
    </div>
  );
}