import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import {Checkbox, InputLabel, Select}  from "@material-ui/core";

const classes = {
    root: {
      width: '100%',

     // backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: "45px",
    },
};
class OptionChildren extends Component {
    constructor(props){
        super(props);
        
        this.state = {
            options:props.options,
            tree:[],
            checkedOptions:[],
            openOptions:[],
            currField:props.currField,
            styles: props.styles,
            selectValue: 'Choose an option',
        }
        this.customizeChildren = this.customizeChildren.bind(this);
        const {tree, checkedOptions} = this.customizeChildren(props.currField);
        this.state.tree = tree;
        this.state.checkedOptions = checkedOptions;
        this.handleToggle = this.handleToggle.bind(this);
        this.selectOption = this.selectOption.bind(this);
        
        
    }
    handleToggle(event){
        event.preventDefault();
        const {openOptions} = this.state;
        const currOption  = event.currentTarget.getAttribute('option');
        const foundIndex = openOptions.findIndex(x=>x===parseInt(currOption,10));
        if(foundIndex !== -1){
            openOptions.splice(foundIndex,1);
        }else{
            openOptions.push(parseInt(currOption,10));
        }
        this.setState({openOptions});
    }
    customizeChildren(){
        const {currField,tree, checkedOptions} = this.state;
        if(currField.children && tree.length == 0){
            currField.children.forEach(child=>{
                const  findIndexOption = tree.findIndex(x=>x.optionId == child.childOptionId);
              
                if(findIndexOption !== -1){
                    if( child.childFieldId !== null){
                        if(!tree[findIndexOption].fields) tree[findIndexOption].fields = [];
                        tree[findIndexOption].fields.push(child.childFieldId);
                        checkedOptions.push(`option-${child.childOptionId}-field-${child.childFieldId }`)
                    }
                }else{
                    let data = {optionId:child.childOptionId};
                    if(child.childFieldId){
                        data.fields = [];
                        data.fields.push(child.childFieldId);
                        checkedOptions.push(`option-${child.childOptionId}-field-${child.childFieldId }`)
                    }
                    checkedOptions.push(`option-${child.childOptionId}`)
                    tree.push(data)
                }
            });
            this.setState({tree,checkedOptions});
            this.props.setOptionFieldChildren(tree);
        }
        return {tree,checkedOptions};
        
    }
    selectOption(event){
        const {checkedOptions,tree,currField,options,selectValue} = this.state;
        const { target: { checked} } = event;
        const option = event.target.getAttribute('option');
        const field = event.target.getAttribute('field');
        const areaLabel = event.target.getAttribute('area-label');
        const findOption = tree.findIndex(x=>x.optionId === option);
        if(checked === true){
            checkedOptions.push(areaLabel);
            const currOptionIndex = options.findIndex(x=> x.optionId == option);
            const currOption = currOptionIndex !== -1 ? options[currOptionIndex] : {};   
            if(findOption === -1){
                let data = {optionId:option};
                if(field){
                    data.fields = []
                    data.fields.push(field);
                }else if(currOption.fields && currOption.fields.length > 0){
                    data.fields = [];
                    currOption.fields.forEach(field=>{
                        data.fields.push(field.fieldId);
                        checkedOptions.push( `option-${option}-field-${field.fieldId}`);
                    });
                }
                tree.push(data)
            }else{
                if(!tree[findOption].fields) tree[findOption].fields = [];
                tree[findOption].fields.push(field)
            }
        }else{
            const foundIndex = checkedOptions.findIndex(x=>x===areaLabel);
            if(foundIndex !== -1){
                checkedOptions.splice(foundIndex,1)
            }
            if(field){
                const fieldIndex = tree[findOption].fields.findIndex(x=>x===field);
                if(fieldIndex !== -1){
                    tree[findOption].fields.splice(fieldIndex,1);
                }
            }else{
                const filtered = checkedOptions.filter(checked => {
                    return checked.search(`option-${option}`) !== -1 ? true :false;
                });
                filtered.forEach(item=> checkedOptions.splice(checkedOptions.findIndex(e => e === item),1) );
                tree.splice(findOption,1);
            }
        }

        if(checkedOptions.length > 0) {
            this.setState({selectValue: 'This option has children'});
        } else {
            this.setState({selectValue: 'Choose an option'})
        }

        this.props.setOptionFieldChildren(tree);
        this.setState({tree,checkedOptions})
    }
    componentWillReceiveProps(props){
        const {options,currField} = props;
        //const {tree, checkedOptions} = this.customizeChildren(props.currField);
        this.setState({options,currField})
    }
    
    render(){
        const {options, currField, openOptions, checkedOptions,selectValue} = this.state;

        return ( 
            <div className="custom-label-child">
                <InputLabel htmlFor="select-multiple-checkbox">Children</InputLabel>
                <Select
                multiple
                value={[selectValue]}
                onChange={this.handleChange}
                name="validations"
                style={classes.root}
                renderValue={selected => selected.join(', ')}
                //MenuProps={MenuProps}
                >  
            <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                style={classes.root}
                >
                {
                    options.map((option,key)=>{
                        if(option.optionId === currField.optionId || !option.id) return "";
                        const labelId = `option-${option.optionId}`;
                        return (
                            <div key={key} >
                            <ListItem button  style={classes.root}>
                                <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={checkedOptions.indexOf(labelId) !== -1}
                                    onChange={this.selectOption}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'area-label': labelId , "option":option.optionId}}
                                />
                                </ListItemIcon>
                                <ListItemText primary={option.label} />
                                {option.fields && option.fields.length >0 ?
                                    openOptions.findIndex(x=>x===option.optionId) !== -1 ?
                                        <ExpandLess onClick={this.handleToggle} option={option.optionId}/> :
                                        <ExpandMore option={option.optionId} onClick={this.handleToggle} />
                                    :
                                    ""
                                }
                            </ListItem>
                            {option.fields && option.fields.length >0 ?
                                <Collapse in={openOptions.findIndex(x=>x===option.optionId) !== -1 ? true: false} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {option.fields.map((field, key)=>{
                                            const labelId = `option-${option.optionId}-field-${field.fieldId}`;
                                            return ( 
                                            <ListItem button key={key} style={classes.nested}>
                                                <ListItemIcon>
                                                <Checkbox
                                                        edge="start"
                                                        checked={checkedOptions.indexOf(labelId) !== -1}
                                                        tabIndex={-1}
                                                        onChange={this.selectOption}
                                                        disableRipple
                                                        inputProps={{ "area-label":labelId, 'option': option.optionId, 'field':field.fieldId }}
                                                    /> 
                                                </ListItemIcon>
                                                <ListItemText primary={field.title} />
                                            </ListItem>)
                                        })}
                                    </List>
                                </Collapse>
                                :
                                ""
                            }
                            </div>
                        )
                    })
                }
            </List>
            </Select>
            </div>
        )
    }
}
export default OptionChildren