// in posts.js
import React from "react";
import './style.css'
import {
  FormTab,
  TabbedForm,
  ShowButton,
  Show,
  TabbedShowLayout,
  Tab,
  List,
  Datagrid,
  Edit,
  Create,
  DateField,
  Filter,
  TextField,
  EditButton,
  DisabledInput,
  ReferenceInput,
  CheckboxGroupInput,
  TextInput,
  required,
  minLength
} from "react-admin";
const validateName = [required(), minLength(2)];
const CompFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn />
  </Filter>
);
export const AdminUserGroupsList = props => (
  <List filters={<CompFilter />} title='Admin Groups' {...props}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <EditButton basePath="/admingroups" />
      <ShowButton basePath="/admingroups" />
    </Datagrid>
  </List>
);
const UserName = ({ record }) => {
  return <span>Admin User Group {record ? `"${record.name}"` : ""}</span>;
};
const handlePermissionChange = (element, data) => {
  console.log('handle change')
  if (data.length === 1) {
    data.push(null);
  }
  return data;
};
const AdminPermissionsGrid = props => {
  let list = <div></div>;
  if (props && props.record && props.record.AdminGroupPermissions) {
    list = (
      <div>
        {props.record.AdminGroupPermissions.map(item => (
          <TextField
            key={item.permissionId}
            record={item}
            source="permission.name"
          />
        ))}
      </div>
    );
  }
  return list;
};
export const AdminUserGroupsShow = props => (
  <Show title={<UserName />} {...props}>
    <TabbedShowLayout className="permissions-list group-show flexible-mui-span-child column-3">
      <Tab label="summary">
        <TextField source="id" />
        <TextField source="name" />
        <DateField label="Publication date" source="createdAt" />
      </Tab>
      <Tab label="permissions">
        <AdminPermissionsGrid />
      </Tab>
    </TabbedShowLayout>
  </Show>
);
export const AdminUserGroupsEdit = props => {
  return (
    <Edit title={<UserName />} {...props}>
        <TabbedForm className="permissions-list admin-users-edit ra-input-bordered content-inner-pt content-inner-pb flexible-card-child column-3">
          <FormTab label="summary">
            <DisabledInput source="id" />
            <TextInput source="name" validate={validateName} />
            <DisabledInput source="createdAt" />
          </FormTab>
          <FormTab label="permissions">
            {props.id !== localStorage.getItem("groupId") ||
            localStorage.getItem("groupId") === '1' ? (
              <ReferenceInput
                label="Permissions"
                source="permissions"
                reference="adminpermissions"
                onChange={handlePermissionChange}
              >
                <CheckboxGroupInput
                  optionText="name"
                  optionValue="id"
                  translateChoice={false}
                />
              </ReferenceInput>
            ) : (
              <AdminPermissionsGrid />
            )}
          </FormTab>
        </TabbedForm>
     
    </Edit>
  );
};

export const AdminUserGroupsCreate = props => (
  <Create title="Create a Admin User Group" {...props}>
    <TabbedForm className="permissions-list admin-users-create ra-input-bordered content-inner-pt content-inner-pb flexible-card-child column-3">
      <FormTab label="summary">
        <DisabledInput source="id" />
        <TextInput source="name" validate={validateName} />
      </FormTab>
      <FormTab label="permissions">
        <ReferenceInput
          label="Permissions"
          source="permissions"
          reference="adminpermissions"
          onChange={handlePermissionChange}
        >
          <CheckboxGroupInput
            optionText="name"
            optionValue="id"
            translateChoice={false}
            row={false}
          />
        </ReferenceInput>
      </FormTab>
    </TabbedForm>
  </Create>
);
