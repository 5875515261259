import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import axios from "axios";
import authHeader from "../_helpers/authHeader";
const useStyles = makeStyles(theme => ({
    formControl: {
      margin: theme.spacing(1),
      width: "100%"
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));
export default function Addresses(props) {
    const classes = useStyles();
    const { value , onChangeValue } = props;
    const [addresses, setAddresses] = React.useState([]);
   
    React.useEffect( () => {
            let query = {userId: props.userId, _start: 0, _end: 50};
            if(props.type){
                query.useAsShipping  = 1;
            }

            axios.get(`${process.env.REACT_APP_API_URL}/addresses`,
            {
                headers:{Authorization:authHeader().Authorization},
                params: query
            }).then((response) =>{
                // console.log(response)
                if(response.status === 200){
                    setAddresses(response.data)
                    // console.log("data ", response.data)
                }
            });
    }, [props.userId, props.type]);

  
  return (
    <FormControl className={classes.formControl} disabled={props.disabled}> 
        <InputLabel id="demo-simple-select-label">{props.title}</InputLabel>
        <Select labelId="demo-simple-select-label" id="demo-simple-select" value={value} onChange={onChangeValue}>
             {addresses ? addresses.map(function(address) {
                return (
                    <MenuItem 
                        key={address.id}
                        value={address.id}
                        control={<Radio color="primary" checked={(value == address.id) ? true : false} />}
                    >{address.street}, {address.region}, {address.city}, {address.country.country_code}</MenuItem>
                );
          }) : "" }
        </Select>
    </FormControl>
  );
}


