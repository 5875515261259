import React, { Component } from 'react';
import axios from "axios";
import authHeader from "../_helpers/authHeader";
import {Edit, required, SimpleForm, TextInput} from "react-admin";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import ErrorDialog from "../orders/errorDialog";
const RequiredValidation = [required()];
const redirect = (basePath, id, data) => `/users/${data.userId}/show/credits`;

class CreditsEdit extends Component {
    constructor(props){
        super(props);
        this.state={
            initialAmount: null,
            error: {
                show: false,
                text: ""
            },
        };
        this.handleAmountChange = this.handleAmountChange.bind(this);
        this.handleSaveAmount = this.handleSaveAmount.bind(this);
        this.handleChangeErrorPopup = this.handleChangeErrorPopup.bind(this);
    }

    async componentDidMount() {
       axios.get(`${process.env.REACT_APP_API_URL}/users/${this.props.match.params.id}`,
            {
                headers:{Authorization:authHeader().Authorization}
            }).then((response) =>{
            if(Object.keys(response.data).length > 0){
                const data = response.data;
                this.setState({initialAmount:data.initialAmount});
            }
        });
    }

    handleAmountChange(val){
        let value = val.target.value;
        if(!/^\d+$/.test(value)) {
            val.preventDefault()
        }
        this.setState({initialAmount:value});
    }

    handleSaveAmount() {
            const { initialAmount } = this.state;
            const formData = new FormData();
            let axiosRequestUrl = `${process.env.REACT_APP_API_URL}/credits/editUserMoney`;
            let userId =   this.props.match.params.id;
            formData.append('initialAmount',initialAmount);
            formData.append('userId',userId);
            axios.put(axiosRequestUrl,formData,
            {
                headers: authHeader()
            }).then((response) =>{
                if(response.data.error) {
                    this.setState({error: {text: response.data.message ,show: true}})
                } else if(response.status === 200) {
                    window.location = `/users/${userId}/show/credits`;
                }
        });
    }

    handleChangeErrorPopup(val) {
        const {error} = this.state;
        error.show = false;
        this.setState(error)
    }
    render(){
        return(
            <div>
                <div className="mb-5">
                    <label>Starting Balance</label><br/><br/>
                    <Input type="number" value={this.state.initialAmount} onChange={this.handleAmountChange} name='initialAmount' />
                </div>

                <ErrorDialog open={this.state.error.show} text={this.state.error.text} onChangeValue = {this.handleChangeErrorPopup} />

                <Button variant="contained" color="primary" className="mr-3" onClick={this.handleSaveAmount}>Save</Button>
            </div>
        )
    }
}

export default CreditsEdit;