import React, { Component } from "react";
import PropTypes from 'prop-types';
import Switch from "react-switch";
import ReactDOM from "react-dom"
import axios from 'axios'

export const NumberFieldik = ({ source, record = {} }) => {
    return <span>{(record[source] || record['userId'])}</span>;
};
NumberFieldik.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export class SwitchExample extends Component {
    constructor({source, record}) {
        super({source, record});
        this.creditId = record.id;
        this.state = {
            checked: record[source],
            loading: false
        };
        this.handleChange = this.handleChange.bind(this);
    }

    async handleChange(checked) {
        try {
            await axios({
                method: 'put',
                url: `${process.env.REACT_APP_API_URL}/credits/userPay/${this.creditId}?paid=${checked}`,
                headers: {
                    authorization: localStorage.getItem('token')
                }
            });

            this.setState({ checked, loading: true });
            window.location.reload();
        } catch (err) {

        }

    }

    render() {
        return (
            <label>
                <Switch onChange={this.handleChange} checked={this.state.checked} />
            </label>
        );
    }
}


export const PdcField = ({ record = {} }) => {
            return <div className='mb-4'>
                Available balance - {record.availableAmount}
                <br/>
                Starting balance - {record.initialAmount}
            </div>
};

PdcField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};
