import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import axios from "axios";
import authHeader from "../_helpers/authHeader";
import "bootstrap/dist/css/bootstrap.min.css";
import {
    Col,
    Row
} from "reactstrap";
const useStyles = makeStyles(theme => ({
    formControl: {
      margin: theme.spacing(1),
      width: "100%"
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));
export default function Statuses(props) {
    const classes = useStyles();
    const { value , onChangeValue, descriptionValue, orderId } = props;

    const [sendData, setSendData] = React.useState(true);
    const [statuses, setStatuses] = React.useState([]);
    const currentStatus = value;
    React.useEffect( () => {

            const query = {currentId: currentStatus, orderId}
            axios.get(`${process.env.REACT_APP_API_URL}/orderstatuses`,
            {
                headers:{Authorization:authHeader().Authorization},
                params: query
            }).then((response) =>{
                if(response.status === 200 && response.data.length > 0){
                    setStatuses(response.data)
                    setSendData(false);
                }
            });
    }, []);

    
  return (
    <FormControl className={classes.formControl + " m-0 status-type"} disabled={props.isDisabled}>
        <Row>
            <Col xs={12} sm={6}>
                <div className="order-card min-h-sm">
                    <div className='order-status position-relative'>
                        <InputLabel id="demo-simple-select-label">ORDER STATUS</InputLabel>
                        <Select labelId="demo-simple-select-label" id="demo-simple-select" value={value} onChange={onChangeValue}>

                            {statuses ? statuses.map(function(status) {

                                return (
                                    <MenuItem
                                        key={status.id}
                                        value={status.id}
                                        control={<Radio color="primary" checked={(value == status.id) ? true : false} />}
                                    >{status.name} - {status.description}</MenuItem>
                                );
                            }) : "" }
                        </Select>
                    </div>
                </div>
            </Col>
            <Col xs={12} sm={6}>
                <div className="order-card min-h-sm">
                    <TextField
                        onChange={onChangeValue}
                        name="description"
                        value={descriptionValue}
                        placeholder="Status Description"
                        multiline={true}
                        rows={2}
                        rowsMax={8}
                        disabled={props.isDisabled}
                    />
                </div>
            </Col>
        </Row>
    </FormControl>
  );
}


