// in src/customRoutes.js
import React from 'react';
import { Route, Router, withRouter} from 'react-router-dom';
import ProductOptions from './product_options/Form';
import Chat from "./chat/index";
import CreateUser from './users/createUser';
import CreateOrder from './orders/createOrder';
import CreditsEdit from './credits/CreditsEdit';
export default [
        <Route key="1" exact path="/orderchat/:id" component={Chat} />,
        <Route key="2" exact path="/productoptions/:id" component={ProductOptions} />,
        <Route key="3" exact path="/users/newUser/" component={CreateUser}  />,
        <Route key="4" exact path="/users/editUser/:id" component={CreateUser}  />,
        <Route key="5" exact path="/orders/createOrder/" component={CreateOrder}  />,
        <Route key="6" exact path="/orders/edit/:id" component={CreateOrder}  />,
        <Route key="7" exact path="/credits/edit/:id" component={CreditsEdit}  />
];