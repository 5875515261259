// in posts.js
import React from "react";
import BuildOutlinedIcon from "@material-ui/icons/BuildOutlined";
import {
  List,
  Show,
  SimpleShowLayout,
  ReferenceInput,
  Datagrid,
  DateField,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  ShowButton,
  DeleteButton,
  DisabledInput,
  BooleanField,
  BooleanInput,
  TextInput,
  SelectInput,
  LongTextInput,
  RichTextField,
  ImageField,
  ImageInput,
  Filter,
  Button,
  TabbedForm,
  FormTab,
  ReferenceManyField,
  NumberInput,
  required,
  number,
  minValue,
  maxValue
} from "react-admin";
import RichTextInput from "ra-input-rich-text";
import './style.css'
import Input from "@material-ui/core/Input";
//export const PostIcon = BookIcon;
const validateName = [required()];
const validateDescription = [required()];
const validateStatus = [required()];
const validateUrl = [required()];


const validatePdfPrice = [number(), required() ,minValue(0.01) , maxValue(10000)];

const ProductsFilter = props => (
  <Filter {...props}>
    <TextInput label="Name" source="name" alwaysOn />
    <SelectInput
      source="status"
      choices={[
        { id: "active", name: "Active" },
        { id: "inactive", name: "Inactive" }
      ]}
    />
  </Filter>
);
const ProductOptionButton = ({ record }) => {
  if (!record || !record.id) return null;
  const url = `/productoptions/${record.id}`;
  return (
    <a href={url} className='color-primary text-decoration-none'>
      {" "}
      <BuildOutlinedIcon /> Options
    </a>
  );
};
export const ProductsList = props => (
  <List filters={<ProductsFilter />} {...props}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="url" />
      <TextField source="status" />
      <EditButton basePath="/products" />
      <ShowButton basePath="/products" />
      <ProductOptionButton />
      {/*<DeleteButton basePath="/products" />*/}
    </Datagrid>
  </List>
);

const ProductTitle = ({ record }) => {
  return <span>Product {record ? `"${record.name}"` : ""}</span>;
};
function formatLogo(value) {
  if (!value || typeof value === "string") {
    // Value is null or the url string from the backend, wrap it in an object so the form input can handle it
    return { url: value };
  } else {
    // Else a new image is selected which results in a value object already having a preview link under the url key
    return value;
  }
}

function onlyNumberAndDecimal(e) {
  let _this = e.target;
  var value = _this.value;
  if(!/^-?\d*[.,]?\d{0,2}$/.test(value)) {
    e.preventDefault()
  }
}

export const ProductsEdit = props => (
  <Edit title={<ProductTitle />} {...props}>
    <TabbedForm className="product-edit ra-input-bordered flexible-mui-span-child content-inner-pt content-inner-pb column-3">
      <FormTab label="summary">
        <DisabledInput source="id" />
        <ReferenceInput
            label="Product type"
            source="typeId"
            reference="productstype"
        >
        <SelectInput optionText="name" optionValue="id" />
        </ReferenceInput>
        <TextInput source="name" validate={validateName} />
        <TextInput source="url" validate={validateUrl} />
        <TextInput source="meta_title" />
        <LongTextInput source="meta_description" />
        <LongTextInput source="short_description" />
        <TextInput source="sale_percent" />
        <TextInput source="pdfPrice" validate={validatePdfPrice}
                   onChange={event => onlyNumberAndDecimal(event)}
        />
        <TextInput source="templateName" />
        <TextInput source="quoteText" />
        <TextInput source="quoteTextColor" />
        <SelectInput
            source="status"
            validate={validateStatus}
            choices={[
              { id: "active", name: "Active" },
              { id: "inactive", name: "Inactive" }
            ]}
        />
        <div>
          <BooleanInput source="testProduct" label="Test Product" />
          <BooleanInput source="additional_upload_file" />
          <BooleanInput source="hasBothSide" />
          <BooleanInput source="hasCustomTemplate" />
          <BooleanInput source="hasAttachFile" />
          <BooleanInput source="priceByColors" />
          <BooleanInput source="onlyPhysical" />
        </div>
        <RichTextInput source="description" validate={validateDescription} className="description-text" />
        <RichTextInput source="gallery_description" className="description-text" />
      </FormTab>
      <FormTab label="Galleries" path="body" contentClassName="gallery-wrap-box">
        <ImageInput multiple source="pictures" label="Upload" accept="image/*">
          <ImageField source="src" title="title" />
        </ImageInput>
        <ReferenceManyField
          reference="galleries"
          target="product_id"
          addLabel={false}
          className="gallery-image-box"
        >
          <Datagrid>
            <ImageField source="file" src="file" label="Files" />
            <TextField source='GalleryType' />
            <EditButton basePath="/galleries" className="gallery-action-btn"/>
            <DeleteButton basePath="/products" redirect={false} className="gallery-action-btn"/>
          </Datagrid>
        </ReferenceManyField>
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const ProductsCreate = props => (
  <Create title="Create Product" {...props}>
    <SimpleForm className="product-create ra-input-bordered flexible-mui-child column-3">
      <DisabledInput source="id" />
      <ReferenceInput
        label="Product type"
        source="typeId"
        reference="productstype"
      >
        <SelectInput optionText="name" optionValue="id" />
      </ReferenceInput>
      <TextInput source="name" validate={validateName} />
      <TextInput source="url" validate={validateUrl} />
      <TextInput source="meta_title" />
      <LongTextInput source="meta_description" />
      <LongTextInput source="short_description" />
      <TextInput source="sale_percent" />
      <TextInput source="pdfPrice" validate={validatePdfPrice}
                 onChange={event => onlyNumberAndDecimal(event)}
      />
      <TextInput source="templateName" />
      <TextInput source="quoteText" />
      <TextInput source="quoteTextColor" />
      <SelectInput
          source="status"
          validate={validateStatus}
          choices={[
            { id: "active", name: "Active" },
            { id: "inactive", name: "Inactive" }
          ]}
      />
      <ImageInput multiple source="pictures" label="Upload" accept="image/*">
        <ImageField source="src" title="title" />
      </ImageInput>
      <div>
        <BooleanInput source="testProduct" />
        <BooleanInput source="additional_upload_file" />
        <BooleanInput source="hasBothSide" />
        <BooleanInput source="hasCustomTemplate" />
        <BooleanInput source="hasAttachFile" />
        <BooleanInput source="priceByColors" />
        <BooleanInput source="onlyPhysical" />
      </div>
      <RichTextInput source="description" className="description-text" validate={validateDescription} />
      <RichTextInput source="gallery_description" className="description-text" />
    </SimpleForm>
  </Create>
);

export const ProductsShow = props => {
  return (
      <Show title={<ProductTitle />} reference="productstype" {...props}>
        <SimpleShowLayout className="product-show ra-field-bordered flexible-child ra-field-column-3">
          <TextField source="id" />
          <TextField source="type.name" label="Product Type" />
          <TextField source="templateName" />
          <TextField source="url" />
          <TextField source="meta_title" />
          <RichTextField source="meta_description" />
          <RichTextField source="short_description" />
          <TextField source="sale_percent" />
          <TextField source="pdfPrice" />
          <TextField source="status" />
          <TextField source="quoteText" />
          <TextField source="quoteTextColor" />
          <DateField label="Publication date" source="createdAt" />
          <BooleanField source="testProduct" label="Test Product" />
          <BooleanField source="additional_upload_file" />
          <BooleanField source="hasBothSide" />
          <BooleanField source="hasCustomTemplate" />
          <BooleanField source="hasAttachFile" />
          <BooleanField source="priceByColors" />
          <BooleanField source="onlyPhysical" />
          <RichTextField source="description" className="description-text"/>
          <RichTextField source="gallery_description" className="description-text"/>
          <ImageField className="product-image-list" multiple source="galleries" src="file" />
        </SimpleShowLayout>
      </Show>
  );
};
