import React from "react";
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  BooleanInput,
  ReferenceInput,
  SimpleShowLayout,
  SelectInput,
  ShowButton,
  BooleanField,
  Filter,
  EditButton,
  Show,
  Edit,
  required
} from "react-admin";

const RequiredValidation = [required()];

const AddressFilter = props => (
  <Filter {...props}>
    <ReferenceInput
      alwaysOn
      label="Country"
      source="countryId"
      reference="countries"
    >
      <SelectInput optionText="country_name" optionValue="id" />
    </ReferenceInput>
    <TextInput label="First Name" source="firstname" />
    <TextInput label="Last Name" source="lastname" />
  </Filter>
);
export const AddressesList = props => (
  <List filters={<AddressFilter />} {...props}>
    <Datagrid>
      <TextField source="user.email" />
      <TextField source="country.country_name" />
      <TextField source="city" />
      <TextField source="firstname" />
      <TextField source="lastname" />
      <TextField source="street" />
      <BooleanField source="isDefaultBilling" />
      <BooleanField source="isDefaultShipping" />
      <BooleanField source="useAsShipping" />
      <EditButton basePath="/addresses" />
      <ShowButton basePath="/addresses" />
    </Datagrid>
  </List>
);

export const AddressesShow = props => (
  <Show {...props}>
    <SimpleShowLayout className="users-show-address ra-field-bordered flexible-child ra-field-column-4">
      <TextField source="userId" />
      <TextField source="country.country_name" />
      <TextField source="city" />
      <TextField source="firstname" />
      <TextField source="lastname" />
      <TextField source="street" />
      <BooleanField source="isDefaultBilling" />
      <BooleanField source="isDefaultShipping" />
      <BooleanField source="useAsShipping" />
    </SimpleShowLayout>
  </Show>
);
const redirect = (basePath, id, data) => `/users/${data.userId}/show/addresses`;

export const AddressesCreate = props => (
  <Create title="Create a address" {...props}>
    <SimpleForm redirect={redirect} className="users-create-address ra-input-bordered flexible-card-child column-4 content-inner-pb">
      <ReferenceInput
        label="country"
        source="countryId"
        validate={RequiredValidation}
        reference="countries"
      >
        <SelectInput optionText="country_name" optionValue="id" />
      </ReferenceInput>
      <TextInput source="firstname" validate={RequiredValidation} />
      <TextInput source="lastname" validate={RequiredValidation} />
      <TextInput source="middliename" />
      <TextInput source="city" validate={RequiredValidation} />
      <TextInput source="company" />
      <TextInput source="fax" />
      <TextInput source="postcode" />
      <TextInput source="region" />
      <TextInput source="street" validate={RequiredValidation} />
      <TextInput source="telephone" validate={RequiredValidation} />
      <BooleanInput
        label="isDefaultBilling"
        source="isDefaultBilling"
        defaultValue={false}
      />
      <BooleanInput
        label="isDefaultShipping"
        source="isDefaultShipping"
        defaultValue={false}
      />
      <BooleanInput
        label="useAsShipping"
        source="useAsShipping"
        defaultValue={true}
      />
    </SimpleForm>
  </Create>
);

export const AddressesEdit = props => (
  <Edit title="edit a address" {...props} className="users-edit-address ra-input-bordered column-4">
    <SimpleForm redirect={redirect}>
      <ReferenceInput label="country" source="countryId" reference="countries">
        <SelectInput optionText="country_name" optionValue="id" />
      </ReferenceInput>
      <TextInput source="firstname" validate={RequiredValidation} />
      <TextInput source="lastname" validate={RequiredValidation} />
      <TextInput source="middliename" />
      <TextInput source="city" validate={RequiredValidation} />
      <TextInput source="company" />
      <TextInput source="fax" />
      <TextInput source="postcode" />
      <TextInput source="region" />
      <TextInput source="street" validate={RequiredValidation} />
      <TextInput source="telephone" validate={RequiredValidation} />
      <BooleanInput label="isDefaultBilling" source="isDefaultBilling" />
      <BooleanInput label="isDefaultShipping" source="isDefaultShipping" />
      <BooleanInput label="useAsShipping" source="useAsShipping" />
    </SimpleForm>
  </Edit>
);
