import React from 'react';
import Radio from '@material-ui/core/Radio';
import { makeStyles } from '@material-ui/core/styles';
import RadioGroup from '@material-ui/core/RadioGroup';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import axios from "axios";
import authHeader from "../_helpers/authHeader";
import { FormGroup } from '@material-ui/core';
import Input from '@material-ui/core/Input';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: "100%"
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const divStyle = {
  display: 'inline-block',
  marginRight: '10px'
};
export default function FormControlLabelPosition(props) {
  const classes = useStyles();
  const { value , onChangeValue,update, orderId} = props;
  const [orderTypes, setOrderType] = React.useState([]);
  const [orderTypePrices, setOrderTypePrices] = React.useState({});




React.useEffect( () => {
    axios.get(`${process.env.REACT_APP_API_URL}/orders/${orderId}`,
        {
            headers:{Authorization:authHeader().Authorization},
        }).then((response) =>{
        if(response.status === 200){
            setOrderType(response.data.orderTypes)

        }
    });
}, []);




return (
    <FormControl component="fieldset" className={classes.formControl} disabled={props.disabled}>
        <FormLabel component="legend">ORDER TYPES</FormLabel>
        <RadioGroup aria-label="position" name="payment" value={value} onChange={onChangeValue} row>
            {orderTypes ? orderTypes.map(function(method) {

                return (
                    <FormControlLabel
                        key={method.key}
                        value={method.id}
                        control={<Checkbox color="primary" checked={method.value===true}/>}
                        label={method.title}
                        labelPlacement={method.title}
                    />
                );
            }) : "" }


        </RadioGroup>
    </FormControl>
);

}


