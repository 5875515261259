import React from 'react';
import { withStyles } from '@material-ui/core';
import {
    Toolbar,
    SaveButton,
    List,
    Datagrid,
    Edit,
    SimpleForm,
    Filter,
    TextField,
    EditButton,
    DisabledInput,
    TextInput,
    required,
    minLength, Show, SimpleShowLayout, BooleanField
} from 'react-admin';

const validateValue = [required(), minLength(2)];

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const ConfigTitle = ({ record }) => {
    return <span>Config email {record ? `"${record.key}"` : ""}</span>;
};

export const ConfigemailsList = (props) => (
    <List {...props} bulkActions={false}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="key" />
            <TextField source="value" />
            <EditButton/>
        </Datagrid>
    </List>
);

export const ConfigemailsEdit = (props) => (
    <Edit title={<ConfigTitle />} {...props}>
        <SimpleForm className='config-email-edit ra-input-bordered flexible-card-child column-3 content-inner-pb'>
            <DisabledInput source="id" />
            <DisabledInput source="key" />
            <TextInput source="value" />
        </SimpleForm>
    </Edit>
);
export const ConfigemailsShow = props => (
    <Show title={<ConfigTitle />} {...props}>
        <SimpleShowLayout className='config-email-show ra-field-bordered flexible-child ra-field-column-3'>
            <TextField source="id" />
            <TextField source="key" />
            <TextField source="value" />
        </SimpleShowLayout>
    </Show>
);