import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { showNotification as showNotificationAction , Button} from 'react-admin';

class ApproveButton extends Component {
    handleClick = (data) => {
        const token = localStorage.getItem('token');
        let id = parseInt(data);

        const request = new Request(`${process.env.REACT_APP_API_URL}/users/newPassword`, {
            method: 'POST',
            body:JSON.stringify({id}),
            headers: new Headers({  'Content-Type': 'application/json' , 'Authorization': `Bearer ${token}`}),
        })
        const { showNotification } = this.props;
        fetch(request)
            .then(async(response) => {
                const text = await response.text();
                const parse = JSON.parse(text);
                if (parse.code === 200) {
                    showNotification(parse.message, 'success');
                 }else{
                    showNotification(parse.error.message, 'warning')
                 }
            })
            .catch((e) => {
                console.error(e);
                showNotification('Error: comment not approved', 'warning')
            });
    }


    render() {
        return <Button variant="contained" color="primary" label="Send Password" onClick={(e) => { if (window.confirm('Are you sure you wish to send email to this user?')) { return this.handleClick(this.props.data)} } } disabled={this.props.isDisabled}/>;
    }
}

ApproveButton.propTypes = {
    showNotification: PropTypes.func,
};

export default connect(null, {
    showNotification: showNotificationAction,
})(ApproveButton);