// LoginPage.js
import React from 'react';
import { Login, LoginForm } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';

const styles = ({
    main: { background: 'grey' },
    // avatar: {
    //     background: '#fff!important',
    //     backgroundRepeat: 'no-repeat',
    //     //backgroundSize: 'contain',
    //     height: 80,
    // },
   // icon: { display: 'none' },
});

const CustomLoginForm = withStyles({
    button: { background: '#F15922' },
})(LoginForm);
//this.containerRef.current.style.backgroundImage = url(${backgroundImage});
const CustomLoginPage = props => (
    <Login
        loginForm={<CustomLoginForm />}
        backgroundImage='none'
        {...props}
    />
);

export default withStyles(styles)(CustomLoginPage);