import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Table, TableBody,TableRow, TableCell, FormControl,FormControlLabel, Checkbox} from '@material-ui/core';

  const useStyles = makeStyles(theme => ({
    formControl: {
      margin: theme.spacing(1),
      width: "100%"
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    color: {
      margin: theme.spacing(1),
      width: "100%",
      color: "red",
      fontSize: 24
    },
  }));
let Products = function Products(props) {
    const classes = useStyles();
    const { isTesting , onChangeValue } = props;

    return (
      <div>
        <Table aria-label="simple table">
            <TableBody>
                <TableRow ><TableCell align="center" className={classes.color} >Products</TableCell></TableRow> 
                
            </TableBody>
        </Table>
        <FormControl component="fieldset" className={classes.formControl}>

            <FormControlLabel
            control={
                <Checkbox
                    checked={isTesting}
                    value={isTesting}
                    onChange={onChangeValue}
                    color="primary"
                    name="isTesting"
                    />
                }
                
                label="TestingOrder"
            />
        </FormControl>
      </div>
    )
  }

  export default Products