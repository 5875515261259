import React from 'react';
import Radio from '@material-ui/core/Radio';
import { makeStyles } from '@material-ui/core/styles';
import RadioGroup from '@material-ui/core/RadioGroup';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import axios from "axios";
import authHeader from "../_helpers/authHeader";
const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: "100%"
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
export default function FormControlLabelPosition(props) {
  const classes = useStyles();
  const { value , onChangeValue, paid, groupId, userId } = props;
  const [sendData, setSendData] = React.useState(true);
  const [paymentMethods, setPaymentMethods] = React.useState();
  React.useEffect( () => {
        axios.get(`${process.env.REACT_APP_API_URL}/paymentmethods`,
        {
            headers:{Authorization:authHeader().Authorization},
        }).then((response) =>{
            if(response.status === 200 && response.data.length > 0){
              let paymentMeth  = response.data;
              let filteredMethods = paymentMeth.filter(function(method) { if(method.key === "pdc" && props.groupId !== 2){ return false} return true});
              setPaymentMethods(filteredMethods)
                setSendData(false);
            }
        });
  }, [props.userId]);

  
  return (
    <FormControl disabled={props.disabled} component="fieldset" className={classes.formControl}>
      <FormLabel component="legend">PAYMENT METHODS</FormLabel>
      <RadioGroup aria-label="position" name="payment" value={value} onChange={onChangeValue} row>
         {paymentMethods ?  paymentMethods.map(function(method) {
            
            return (
                <FormControlLabel
                    key={method.id}
                    value={method.id}
                    control={<Radio color="primary" checked={(value == method.id) ? true : false} />}
                    label={method.name}
                    labelPlacement={method.name}
                />
            );
          }) : "" }

        
      </RadioGroup>
      {/* <FormControlLabel
                control={
                    <Checkbox
                        checked={paid}
                        value={paid}
                        onChange={onChangeValue}
                        color="primary"
                        name="paid"
                    />
                }
                
                label="Paid"
            /> */}
    </FormControl>
  );
}


