import React from "react";
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  Show,
  Edit,
  required
} from "react-admin";
const RequiredValidation = [required()];

const redirect = (basePath, id, data) => `/users/${data.userId}/show/credits`;

export const CreditsList = props => (
  <List {...props}>
    <Datagrid>
      <TextField source="userId" />
    </Datagrid>
  </List>
);

export const CreditsShow = props => (
  <Show {...props}>
    <Datagrid>
      <TextField source="userId" />
    </Datagrid>
  </Show>
);

export const CreditsCreate = props => (
  <Create title="Create a address" {...props}>
    <SimpleForm redirect={redirect}>
      <TextInput
        source="initialAmount"
        defaultValue="0"
        validate={RequiredValidation}
        label='Starting amount'
        className='w-50'
      />
    </SimpleForm>
  </Create>
);

export const CreditsEdit = props => (
  <Edit title="edit a address" {...props}>
    <SimpleForm redirect={redirect}>
      <TextInput source="amount" validate={RequiredValidation} />
      <TextInput source="initialAmount" validate={RequiredValidation} />
    </SimpleForm>
  </Edit>
);
