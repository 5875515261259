import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS } from "react-admin";

export default (type, params) => {
  console.log(type,params)
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    const request = new Request(
      `${process.env.REACT_APP_API_URL}/adminusers/authenticate`,
      {
        method: "POST",
        body: JSON.stringify({ username, password }),
        headers: new Headers({ "Content-Type": "application/json" })
      }
    );
    return fetch(request)
      .then(async response => {
        return  response.text()
          .then(text=>{
    
            if (response.status < 200 || response.status >= 300) {
              if (text !== "" && text !== undefined) {
                throw new Error(text);
              } else {
                throw new Error(response.statusText);
              }
            }
            let tokenJson = JSON.parse(text);
            if (tokenJson.token) {
              const permissions = {};
              for (const iterator of tokenJson.permissions) {
                const path = iterator.permission.path;
                const pathArr = path.split("/");
                const resource = pathArr[2];
  
                if (resource && resource !== undefined) {
                  if (
                    !permissions[resource] ||
                    permissions[resource] === null ||
                    permissions[resource] === undefined
                  )
                    permissions[resource] = [];
               
                  if (
                    !permissions[resource].includes(iterator.permission.reactAction)
                  ) {
                    permissions[resource].push(iterator.permission.reactAction);
                  }
                }
              }
              localStorage.setItem("permissions", JSON.stringify(permissions));
              localStorage.setItem("token", tokenJson.token);
              localStorage.setItem("groupId", tokenJson.groupId);
              window.location='/adminusers';
            } else {
              throw new Error("Invalid Response");
            }
          })
        
      })
  }
  if (type === AUTH_LOGOUT) {
    // localStorage.removeItem("token");
    // localStorage.removeItem("groupId");
    // localStorage.removeItem("permissions");

    localStorage.clear();
    return Promise.resolve();
  }
  if (type === AUTH_ERROR) {
    const status = params.status;
    if (status === 401 || status === 403) {
      // localStorage.removeItem("token");
      // localStorage.removeItem("groupId");
      // localStorage.removeItem("permissions");

      localStorage.clear();
      return Promise.reject({ redirectTo: "/login" });
    }
    return Promise.resolve();
  }
  if(type == AUTH_GET_PERMISSIONS){
    return localStorage.getItem("token")
      ? Promise.resolve(localStorage.getItem("permissions"))
      : Promise.reject({ redirectTo: "/login" });
  }
  if (type === AUTH_CHECK) {
    return localStorage.getItem("token")
      ? Promise.resolve({ redirectTo: '/custom-url' })
      : Promise.reject({ redirectTo: "/login" });
  }
  return Promise.reject("Unknown method");
};
