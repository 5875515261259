import React, { Component } from 'react';
import {Button} from '@material-ui/core';
import OptionItemRowItem from "./OptionItemRowItem";
import AddIcon from '@material-ui/icons/Add';
import {deleteOptionField} from "../services/options"
const styles = {
    button: {
        padding:"3px",
        fontSize:"12px",
        float:"left"
    }
}
class OptionItemRows extends Component {

    constructor(props){
        super(props);
        this.state = {
            options:props.options,
            fields:props.option.fields ? props.option.fields:[],
            option:props.option,
            type:props.type,
            useStyles:props.useStyles,
            typeId:props.typeId
        }   
        this.rowChanged = this.rowChanged.bind(this);
        this.addEmptyRow = this.addEmptyRow.bind(this);
        this.deleteOptionField = this.deleteOptionField.bind(this);
        this.setOptionChildren = this.setOptionChildren.bind(this);
    }
    componentWillReceiveProps(props){
        const {fields,option,type,options} = props;
        this.setState({fields,option,type,options})
    }
    setOptionChildren(children){
        const {option} = this.state;
        option.children = children;
        this.props.changeValue(option);
    }
    deleteOptionField(id){
        const { fields } = this.state;
        const index = fields.findIndex(x=> x.fieldId === id);
        if(index !== -1){
            const currentComponent = this;
 
            if(fields[index].id){
                deleteOptionField(fields[index].id)
                    .then(deleted=>{
                        fields.splice(index,1); 
                        currentComponent.setState(fields)
                    })
                    .catch(error=>{
                        console.log('erro deleted',error)
                    })     
            }else{
                fields.splice(index,1); 
                currentComponent.setState(fields)
            }   
        }
        
    }
    addEmptyRow(){
        const {fields,option} = this.state;
        fields.push({new:true,fieldId: `field_${fields.length}`,title:"",price:0,optionId:option.optionId,sortorder:0,isDefault:false});
        this.setState({fields});
        this.props.addRows(fields);
    }
    rowChanged(field){
        const {fields} = this.state;
        const index = fields.findIndex(x=>x.fieldId === field.fieldId);
        if(index !== -1){
            field[index] = field;
            this.setState({fields});
            this.props.addRows(fields);
        } 
    }
    render(){
        const {fields, useStyles,options,typeId} = this.state;
        return (
            <div>
                <Button color="primary" onClick={this.addEmptyRow} variant="contained"><AddIcon fontSize="inherit"/> Add Row</Button>
                {
                    fields.map(field=> {
                        const key = field.fieldId ? field.fieldId : field.id;
                       return <OptionItemRowItem key={key} 
                                setOptionChildren={this.setOptionChildren}
                                rowChanged={this.rowChanged} 
                                deleteOptionField={this.deleteOptionField} 
                                useStyles={useStyles} 
                                options={options}
                                typeId={typeId}
                                field={field} />
                    })
                }
            </div>
        )
    }
}
export default OptionItemRows