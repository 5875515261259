import React, { Component } from 'react';
import {Table, TableBody,TableRow, TableCell} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { red } from '@material-ui/core/colors';

let adminsList = function adminsList(props) {
    const {onChange} = props
    let keys = Object.keys(props.admins);
    return (
        <Table aria-label="simple table">
            <TableBody>
                <TableRow ><TableCell align="left">SUPPORT ADMINS</TableCell></TableRow> 
                { Object.values(props.admins).map(function(value, index) {
                    return <TableRow key={index}>
                        <TableCell align="left">
                            <div className="flex-box">
                            <div className="text">{value}</div>
                            <DeleteIcon className='delete-btn' onClick={() => onChange(keys[index])} />
                            </div>
                        </TableCell>
                        </TableRow> 
                })}
            </TableBody>
        </Table>
    )
  }

  export default adminsList