import React, { Component } from 'react';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';

import {TextField,FormControl,FormControlLabel,Switch} from '@material-ui/core';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
class Validations extends Component {

    constructor(props){
        super(props);
        this.state = {
            option: props.option,
            validations:props.allValidations,
            selectedValidations:props.validations,
            styles:props.styles
        }        
        this.handleChange = this.handleChange.bind(this);
        this.setValue = this.setValue.bind(this);
    }
    componentWillReceiveProps(props){
      const {allValidations,validations, option}  = props;
      this.setState({validations:allValidations,selectedValidations:validations,option});
    }
    setValue(event){
      const {selectedValidations}  = this.state;
      const { target: {  value, type, name} } = event;
      const foundIndex = selectedValidations.findIndex(x=>x.index === name);
      if(foundIndex !== -1){
        selectedValidations[foundIndex].value = value;
        if(type === 'checkbox'){
          selectedValidations[foundIndex].value =  event.target.checked
        }
      }
      this.props.addValidationsToObject(selectedValidations);
      
    }
    handleChange(event){
      const { target: {  value} } = event;
      const {validations,selectedValidations, option} = this.state;
      const index = value[0];
      const foundIndex = selectedValidations.findIndex(x=> x.index === index);
      if(foundIndex !== -1 && selectedValidations[foundIndex]){
         selectedValidations.splice(foundIndex,1);
      }else{
        const selectedValidation =  validations[validations.findIndex(x=>x.index === index)];
        const validation = {
            index: selectedValidation.index,
            label: selectedValidation.label,
            value: "",
            appendedTagType : selectedValidation.appendedTagType,
            validationId: selectedValidation.id,
            id: `validation-${option}-${selectedValidation.index}`
        }
        selectedValidations.push(validation);
      }
      this.props.addValidationsToObject(selectedValidations);
    }
  
    render(){
        const {validations,selectedValidations,styles} = this.state;
        if(!validations || validations.length === 0) return <div></div>;
        let selected=[];
        return (   
            <div>
                <FormControl style={styles.formControl}>
                <InputLabel htmlFor="select-multiple-checkbox">Validations</InputLabel>
                    <Select
                    multiple
                    value={selected}
                    onChange={this.handleChange}
                    input={<Input id="select-multiple-checkbox" />}
                    name="validations"
                    renderValue={selected => selected.join(', ')}
                    MenuProps={MenuProps}
                    >
                    {validations.map(validation => (                
                        <MenuItem key={validation.index} value={validation.index} name={validation.index}>
                            <Checkbox checked={selectedValidations.findIndex(x=>x.index === validation.index) !== -1 ? true: false} />
                            <ListItemText primary={validation.label} />
                        </MenuItem>
                    ))}
                    </Select>
          
                </FormControl>
                {selectedValidations.map((validation,key )=> {    
                      const htmlTag =  validation.appendedTagType;
                      let tag= null; 
                      let type= null ;
                      if(htmlTag){
                          [tag,type] = htmlTag.split('|');
                      } 
                      if(tag === 'input'){
                         switch(type){
                          case "checkbox":
                              return <FormControlLabel key={validation.id}
                                  control={
                                    <Switch
                                    checked={true}
                                    disabled
                                    onChange={this.setValue}
                                    name={validation.index}
                                    color="primary"
                                  />
                                  }
                                  label={validation.label}                
                        /> 
                          case "number":
                              return <FormControl style={styles.formControl} key={validation.id}>
                              <TextField
                                id={`validartion_${validation.index}`}
                                label={validation.label}
                                className={styles.textField}
                                value={validation.value}
                                name={validation.index}
                                type="number"
                                onChange={this.setValue}
                                margin="normal"
                            />
                            </FormControl> 
                          default:
                              return <FormControl style={styles.formControl} key={validation.id}>
                                <TextField
                                  id={`validartion_${validation.index}`}
                                  label={validation.label}
                                  className={styles.textField}
                                  value={validation.value}
                                  name={validation.label}
                                  onChange={this.setValue}
                                  margin="normal"
                            />
                          </FormControl> 

                        }
                      }
                      
                    })}
            </div>
        )
    }
}
export default Validations