import React from "react";
import {
  FormTab,
  TabbedForm,
  ShowButton,
  Show,
  TabbedShowLayout,
  Tab,
  List,
  Datagrid,
  Edit,
  BooleanInput,
  Create,
  Filter,
  TextField,
  EditButton,
  DisabledInput,
  ReferenceInput,
  CheckboxGroupInput,
  required,
  minLength,
  TextInput
} from "react-admin";
import './style.css'
const validateName = [required(), minLength(2)];
const RoleFilter = props => (
  <Filter {...props}>
    <TextInput label="Name" source="name" alwaysOn />
  </Filter>
);
const RolesPermissionsGrid = props => {
  let list = <div></div>;

  list = (
    <div>
      {props.record.rolePermissions.map(item => (
        <TextField key={item.id} record={item} source="name" />
      ))}
    </div>
  );
  return list;
};
export const RolesList = props => {
  let list = (
    <List filters={<RoleFilter />} {...props}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="description" />
        <EditButton basePath="/roles" />
        <ShowButton basePath="/roles" />
      </Datagrid>
    </List>
  );
  return list;
};

export const RolesShow = props => (
  <Show title={<UserName />} {...props}>
    <TabbedShowLayout className='permissions-list member-show content-inner-pt flexible-mui-span-child column-3'>
      <Tab label="Role">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="description" />
      </Tab>
      <Tab label="Permissions">{<RolesPermissionsGrid />}</Tab>
    </TabbedShowLayout>
  </Show>
);

const handlePermissionChange = (element, data) => {
  if (data.length === 1) {
    data.push(null);
  }
  return data;
};
const UserName = ({ record }) => {
  return <span>Admin User Group {record ? `"${record.name}"` : ""}</span>;
};
export const RolesEdit = props => {
  return (
    <Edit title={<UserName />}  {...props}>
      <TabbedForm className='permissions-list member-edit content-inner-pt content-inner-pb flexible-mui-span-child column-4'>
        <FormTab label="summary">
          <DisabledInput source="id" />
          <TextInput source="name" validate={validateName} />
          <TextInput source="description" />
          <BooleanInput label="Active" source="active" />
        </FormTab>
        <FormTab label="permissions">
          <ReferenceInput
            label="Permissions"
            source="permissions"
            reference="rolesPermissions"
            onChange={handlePermissionChange}
          >
            <CheckboxGroupInput
              optionText="name"
              optionValue="id"
              translateChoice={false}
            />
          </ReferenceInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export const RolesCreate = props => (
  <Create {...props}>
    <TabbedForm className='permissions-list member-create content-inner-pt content-inner-pb flexible-mui-span-child column-4'>
      <FormTab label="summary">
        <DisabledInput source="id" />
        <TextInput source="name" validate={validateName} />
        <TextInput source="description" />
        <DisabledInput source="createdAt" />
      </FormTab>
      <FormTab label="permissions">
        <ReferenceInput
          label="Permissions"
          source="permissions"
          reference="rolesPermissions"
          onChange={handlePermissionChange}
        >
          <CheckboxGroupInput
            optionText="name"
            optionValue="id"
            translateChoice={false}
          />
        </ReferenceInput>
      </FormTab>
    </TabbedForm>
  </Create>
);
