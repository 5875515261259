import React, { Component } from 'react';
import {Table, TableBody,TableRow, TableCell, Button} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

let membersList = function membersList(props) {
    const {onChange, value} = props
    let keys = Object.keys(props.members);
    return (
        <Table aria-label="simple table">
            <TableBody>
                <TableRow ><TableCell align="left">MEMBERS</TableCell></TableRow>
                { Object.values(props.members).map(function(val, index) {
                    return <TableRow key={index}>
                        <TableCell align="left" className='text-break d-flex justify-content-between'>
                            {val}
                            <DeleteIcon className='delete-btn' onClick={() => onChange(keys[index])} value={val} />
                        </TableCell>
                    </TableRow>
                })}
            </TableBody>
        </Table>
    )
  };

  export default membersList