import React, {Component} from "react";
// import Select from 'react-dropdown-select';
// import Dropdown from "react-dropdown";
import Select from "react-select";

import validator from "validator";
import FormValidator from "./validations";
import authHeader from "../_helpers/authHeader";

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import axios from "axios";
import Button from "@material-ui/core/Button";
import InputMask from "react-input-mask";
import SVG from "./svg.json"

const apiUrl = process.env.REACT_APP_API_URL;

var qs = require("query-string");

class ProductOptionsPopup extends Component {
    constructor(props) {
        super(props);

        const productId = props.productId;
        const productName = props.productName;
        const optionsJson = props.optionsJson;
        this.state = {
            productId,
            productName,
            optionsJson,
            errors: [],
            errorClasses: [],
            template: "",
            productOptions: [],
            productField: [],
            productCheckBoxList: [],
            productTemplate: "",
            orderProductData: [],
            dragging: false,
            open: false,
            price: 0,
            discount: 0,
            salePrice: 0,
        };

        this.templateDropdownChange = this.templateDropdownChange.bind(this);
        this.setupProductForState = this.setupProductForState.bind(this);
        this.getTemplateByProduct = this.getTemplateByProduct.bind(this);
        this.getProductChildren = this.getProductChildren.bind(this);
        this.handleSwatchChange = this.handleSwatchChange.bind(this);
        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
        this.getProductOption = this.getProductOption.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
        this.drawNumberField = this.drawNumberField.bind(this);
        this.getTemplateData = this.getTemplateData.bind(this);
        this.configOptions = this.configOptions.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.drawOptions = this.drawOptions.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.addProduct = this.addProduct.bind(this);
        this.financial = this.financial.bind(this);

    }

    _isMounted = false;
    _isProductField = false;

    handleValidation() {
        let errors = {};
        let errorClasses = {};
        let formIsValid = true;
        let {productOptions, productField} = this.state;
        const validationRules = [];
        Object.keys(productOptions).map(function (i) {
            if (productOptions[i].required === true && productOptions[i].fieldType !== 'checkbox' && productOptions[i].fieldType !== 'staticText') {
                var validationRule = {
                    field: productOptions[i].id,
                    method: validator.isEmpty,
                    validWhen: false,
                    message: "This field is required"
                };
                if (productOptions[i].fieldType === "number" && productOptions[i].min !== undefined) {
                    let fieldMin = productOptions[i].min;
                    let fieldMax = productOptions[i].max;
                    let units = productOptions[i].units;

                    let unitsText = "";

                    if (units === "mm") {
                        unitsText = "mm";
                    }
                    if (units === "percent") {
                        unitsText = "%";
                    }
                    validationRule = {
                        field: productOptions[i].id,
                        method: validator.isEmpty,
                        validWhen: false,
                        message: `minimum ${fieldMin}${unitsText} and maximum ${fieldMax}${unitsText}`
                    };
                }
                if (productOptions[i].fieldType === "cylinder") {
                    validationRule = {
                        field: productOptions[i].id,
                        method: validator.isLength,
                        args: [
                            {
                                min: 3
                            }
                        ],
                        validWhen: true,
                        message: "Minimum digits qty : 3"
                    };
                }
                validationRules.push(validationRule);
            }

            return validationRule;
        });
        const validatorObj = new FormValidator(validationRules);
        const validation = validatorObj.validate(productField);
        if (!validation.isValid) {
            formIsValid = false;
            for (var variable in validation) {
                if (validation.hasOwnProperty(variable) && variable !== "isValid") {
                    if (validation[variable]["isInvalid"]) {
                        errors[variable] = validation[variable]["message"];
                        errorClasses[variable] = "is-invalid";
                    }
                }
            }
        }
        this.setState({errors: errors, errorClasses: errorClasses});
        return formIsValid;
    }

    componentDidMount() {
        let {productId, productName, optionsJson} = this.state;
        if (optionsJson) {
            this.getProductChildren(optionsJson);
        } else {
            this.getProductOption(productId);
        }
        this.dragCounter = 0;
    }

    getTemplateByProduct = async () => {
        let {productId, templateId} = this.state;
        let requestUrl = `/templates/getTemplateByProduct/${productId}`;
        let currentComponent = this;
        let res = await axios.get(apiUrl + requestUrl, {
            headers: {Authorization: authHeader().Authorization}
        });

        let {data} = res;

        if (data.error === null) {
            if (data.payload.isEmpty !== true) {
                this.setState(
                    {
                        templateOptions: data.payload.templates,
                        templateField: true
                    },
                    () => {
                        this.getProductOption(productId);
                    }
                );
            } else {
                this.getProductOption(productId);
            }

            let templateIdFromUrl = "";
            if (window.location.search) {
                let id = qs.parse(window.location.search, {
                    ignoreQueryPrefix: true
                }).templateId;
                if (id !== undefined && id !== "") {
                    templateIdFromUrl = {value: id};
                    this.templateDropdownChange(templateIdFromUrl, "");
                }
            }
        }
    };
    getProductOption = async productId => {
        let requestUrl = `/orderproductoptions/${productId}`;

        let currentComponent = this;

        let res = await axios.get(apiUrl + requestUrl, {
            headers: {Authorization: authHeader().Authorization}
        });

        let {data} = res;

        this.setState({
            productOptions: data.payload.options
        });
        this.setupProductForState(data.payload.options);
    };

    getProductChildren = async (templateData = "") => {
        let loader = document.getElementById("loader");
        if (loader !== null) {
            loader.classList.add("show");
        }
        let requestUrl = `/getoptionchildren`;
        let currentComponent = this;
        let {productId, productField, productCheckBoxList} = this.state;
        var selected = {};
        if (templateData === "") {
            productField.map((item, key) => {
                selected[key] = item;
            });
            Object.keys(productCheckBoxList).map(function (key, item) {
                return selected[key] = productCheckBoxList[key]
            })
        } else {
            selected = templateData;
        }
        const queryParams = {
            productId: productId,
            selected: templateData === "" ? JSON.stringify(selected) : selected
        };
        // queryParams.productId = productId;
        // queryParams.selected = JSON.stringify(productField);
        let res = await axios.post(apiUrl + requestUrl, queryParams, {
            headers: {Authorization: authHeader().Authorization}
        });

        let {data} = res;
        this.setupProductForState(data.payload.options);
        this.setState(
            {
                productOptions: data.payload.options
            },
            () => {
                this.calculatePrice();
                if (loader !== null && loader.classList.contains("show")) {
                    loader.classList.remove("show");
                }
            }
        );
    };

    configOptions(options) {
        if (options.length === 0) return [];
        const customizedOptions = [];
        const oneFieldsOptions = ["swatch", "textarea", "checkbox", "staticText", "downloadableFile"];
        for (let i = 0; i <= options.length - 1; i++) {
            const item = options[i];
            if (item.isForOneLine) {
                customizedOptions.push({
                    items: [item],
                    half: false,
                    isForOneLine: true
                });
            } else {
                if (oneFieldsOptions.includes(item.fieldType)) {
                    customizedOptions.push({
                        items: [item],
                        half: false,
                        isForOneLine: false
                    });
                } else {
                    const lastItem = customizedOptions[customizedOptions.length - 1];
                    if (
                        lastItem &&
                        lastItem !== undefined &&
                        lastItem.half &&
                        lastItem.items.length === 1
                    ) {
                        customizedOptions[customizedOptions.length - 1].items.push(item);
                    } else {
                        customizedOptions.push({
                            items: [item],
                            half: true,
                            isForOneLine: false
                        });
                    }
                }
            }
        }
        return customizedOptions;
    }

    setupProductForState(data) {

        let field = [];
        let customTemplateField = [];
        const checkBoxList = {};
        var selected = {}
        let customTemplateCheckBoxList = {};


        for (var i = 0; i <= data.length - 1; i++) {
            const item = data[i];
            if (item.fieldType === "swatch") {
                if (item.value !== "") {
                    field[item.id] = item.value;
                    if (item.isForCustomTemplate) {
                        customTemplateField[item.id] = item.value
                    }
                } else {
                    // field[item.id] = `${item.optionsFields[0].id}`;
                    field[item.id] = ``;
                    if (item.isForCustomTemplate) {
                        customTemplateField[item.id] = `${item.optionsFields[0].id}`;
                    }
                }
            } else if (item.fieldType === "downloadableFile") {
                if (item.value !== "") {
                    field[item.id] = item.value;
                } else {
                    // field[item.id] = `${item.optionsFields[0].id}`;
                    field[item.id] = ``;
                }
            } else if (item.fieldType === "checkbox") {
                let itemId = item.id;
                let options = item.optionsFields;


                for (var j = 0; j <= options.length - 1; j++) {
                    const optionItem = options[j]
                    const optionID = optionItem.id
                    const optionVal = optionItem.selected
                    selected[optionID] = optionVal
                    if (optionItem.parentId === itemId) {
                        checkBoxList[itemId] = checkBoxList[itemId] === undefined ? {} : checkBoxList[itemId]
                        checkBoxList[itemId][optionID] = optionVal
                    }
                    // document.getElementById(optionID).checked = optionVal.selected;

                }
                if (item.isForCustomTemplate) {
                    for (var j = 0; j <= options.length - 1; j++) {
                        const optionItem = options[j]
                        const optionID = optionItem.id
                        const optionVal = optionItem.selected
                        if (optionItem.parentId === itemId) {
                            customTemplateCheckBoxList[itemId] = customTemplateCheckBoxList[itemId] === undefined ? {} : checkBoxList[itemId]
                            customTemplateCheckBoxList[itemId][optionID] = optionVal
                        }
                        // document.getElementById(optionID).checked = optionVal.selected;

                    }
                }
            } else if (item.fieldType === "staticText") {

            } else {
                field[item.id] = item.value;
                if (item.isForCustomTemplate) {
                    customTemplateField[item.id] = item.value;
                }
            }
        }
        this.setState(
            {
                productCheckBoxList: checkBoxList,
                customTemplateCheckBoxList: customTemplateCheckBoxList,
                checkBox: selected,
                productField: field,
                customTemplateProductField: customTemplateField,
                productFieldReady: true
            },
            () => {
            }
        );
    }

    drawOptions() {
        let {productOptions} = this.state;
        const productOptionData = this.configOptions(productOptions);
        return productOptionData.map((list, key) => {
            let items = list.items;
            if (list.isForOneLine) {
                return items.map((item, key) => {
                    if (item.fieldType === "swatch") {
                        return this.drawSwatchField(item);
                    }
                    if (item.fieldType === "downloadableFile") {
                        return this.drawDownloadableFileSwatchField(item);
                    }
                    if (item.fieldType === "textarea") {
                        return this.drawTextAreaField(item);
                    }
                    if (item.fieldType === "checkbox") {
                        return this.drawCheckboxField(item);
                    }
                    if (item.fieldType === "staticText") {
                        return this.drawStaticText(item);
                    }

                    if (item.fieldType === "number") {
                        return (
                            <div className="option-row row-half clear" key={item.id}>
                                {this.drawNumberField(item)}
                            </div>
                        )
                    }
                    if (item.fieldType === "cylinder") {
                        return (
                            <div className="option-row row-half clear" key={item.id}>
                                {this.drawCylinderField(item)}
                            </div>
                        )
                    }
                    if (item.fieldType === "text") {
                        return (
                            <div className="option-row row-half clear" key={item.id}>
                                {this.drawTextField(item)}
                            </div>
                        )
                    }
                    if (item.fieldType === "dropdown") {
                        return (
                            <div className="option-row row-half clear" key={item.id}>
                                {this.drawDropDownField(item)}
                            </div>
                        )
                    }
                    return true
                });
            } else {
                if (list.half) {
                    return (
                        <div className="option-row row-half clear" key={key}>
                            {items.map((item, key) => {
                                if (item.fieldType === "number") {
                                    return this.drawNumberField(item);
                                }

                                if (item.fieldType === "cylinder") {
                                    return this.drawCylinderField(item);
                                }
                                if (item.fieldType === "text") {
                                    return this.drawTextField(item);
                                }
                                if (item.fieldType === "dropdown") {
                                    return this.drawDropDownField(item);
                                }
                                return true
                            })}
                        </div>
                    );
                } else {
                    return items.map((item, key) => {
                        if (item.fieldType === "swatch") {
                            return this.drawSwatchField(item);
                        }
                        if (item.fieldType === "downloadableFile") {
                            return this.drawDownloadableFileSwatchField(item);
                        }
                        if (item.fieldType === "textarea") {
                            return this.drawTextAreaField(item);
                        }
                        if (item.fieldType === "checkbox") {
                            return this.drawCheckboxField(item);
                        }
                        if (item.fieldType === "staticText") {
                            return this.drawStaticText(item);
                        }
                        return true
                    });
                }
            }

        });
    }

    drawStaticText(data) {
        return (
            <div className="option-row row-static-text" key={data.id}>
                {data.description}
            </div>
        );
    }

    drawCheckboxField(data) {
        const {errors} = this.state;
        return (
            <div className="option-row" key={data.id}>
                <div className="label"><span className="name">{data.label}</span> {data.required &&
                <span className="sign">*</span>}</div>
                <div className="wrap-box option-row row-half">
                    {data.optionsFields.map((list, key) => {
                        return (
                            <div className="item" key={list.id}>
                                <input
                                    type="checkbox"
                                    id={list.id}
                                    defaultChecked={list.selected}
                                    name={data.id}
                                    onChange={this.handleCheckBoxChange}
                                    data-refresh={data.need_reload}
                                />
                                <label
                                    htmlFor={list.id}
                                    className={`custom-label-checkbox  ${list.image ? "with-image" : ""}`}
                                >
                                    {list.image ? <img src={list.image} alt={list.title}/> : ""}
                                    <div className="icons">
                                        <svg className="icon-check" xmlns="http://www.w3.org/2000/svg"
                                             viewBox="0 0 14 14">
                                            <rect className="cls-1" width="14" height="14"/>
                                            <polyline className="cls-2" points="1.27 7.23 4.93 10.9 12.73 3.1"/>
                                        </svg>
                                        <svg className="icon-plus" xmlns="http://www.w3.org/2000/svg"
                                             viewBox="0 0 14 14">
                                            <rect className="cls-1" width="14" height="14"/>
                                            <path className="cls-2" d="M7,11.5v-9M2.5,7h9"/>
                                        </svg>
                                    </div>
                                    <div className="text">
                                        <div className="text-box">
                                            {list.title}
                                        </div>
                                    </div>
                                </label>
                            </div>
                        );
                    })}
                    {data.description !== '' && data.description !== undefined ? (
                        <div className="tooltip">
                            <svg className="question-icon" xmlns="http://www.w3.org/2000/svg" width="100" height="100"
                                 viewBox="0 0 100 100">
                                <path
                                    d="M-6494-54a49.689,49.689,0,0,1-19.463-3.929,50.069,50.069,0,0,1-8.493-4.61,50.362,50.362,0,0,1-7.4-6.105,50.354,50.354,0,0,1-6.105-7.4,49.954,49.954,0,0,1-4.609-8.493A49.679,49.679,0,0,1-6544-104a49.682,49.682,0,0,1,3.93-19.463,49.957,49.957,0,0,1,4.609-8.493,50.361,50.361,0,0,1,6.105-7.4,50.362,50.362,0,0,1,7.4-6.105,50.072,50.072,0,0,1,8.493-4.61A49.689,49.689,0,0,1-6494-154a49.688,49.688,0,0,1,19.462,3.929,50.013,50.013,0,0,1,8.493,4.61,50.382,50.382,0,0,1,7.4,6.105,50.416,50.416,0,0,1,6.105,7.4,50.031,50.031,0,0,1,4.609,8.493A49.688,49.688,0,0,1-6444-104a49.685,49.685,0,0,1-3.93,19.462,50.028,50.028,0,0,1-4.609,8.493,50.416,50.416,0,0,1-6.105,7.4,50.381,50.381,0,0,1-7.4,6.105,50.018,50.018,0,0,1-8.493,4.61A49.688,49.688,0,0,1-6494-54Zm-.4-31.651a6.4,6.4,0,0,0-4.606,1.732,5.873,5.873,0,0,0-1.8,4.4,5.822,5.822,0,0,0,1.761,4.341,6.4,6.4,0,0,0,4.646,1.712,6.412,6.412,0,0,0,4.647-1.712A5.826,5.826,0,0,0-6488-79.517a5.873,5.873,0,0,0-1.8-4.4A6.4,6.4,0,0,0-6494.4-85.65Zm.791-37.675a6,6,0,0,1,4.627,1.683,7.283,7.283,0,0,1,1.543,5.049,8.251,8.251,0,0,1-1.187,4.241,25.425,25.425,0,0,1-4.765,5.217,18.287,18.287,0,0,0-4.924,6.511,24.192,24.192,0,0,0-1.344,8.881h10.122l.158-2.43a11,11,0,0,1,3.441-6.81l3.2-3.066a26.4,26.4,0,0,0,5.26-6.671,14.161,14.161,0,0,0,1.5-6.392,17.714,17.714,0,0,0-1.156-6.606,12.939,12.939,0,0,0-3.47-4.923,15.214,15.214,0,0,0-5.566-3.062,24.975,24.975,0,0,0-7.444-1.021,23.885,23.885,0,0,0-7.429,1.075,15.839,15.839,0,0,0-5.68,3.226,13.9,13.9,0,0,0-3.623,5.127,18.328,18.328,0,0,0-1.261,6.78h11.467a7.179,7.179,0,0,1,1.839-5A6.264,6.264,0,0,1-6493.613-123.325Z"
                                    transform="translate(6544 153.999)"
                                    fill="#8f9293"/>
                            </svg>
                            <div className="left">
                                {data.description}
                            </div>
                        </div>
                    ) : ('')}
                </div>
                <div className="invalid-feedback">{errors[[data.id]]}</div>

            </div>
        );
    }

    drawNumberField(data) {
        const {errors, errorClasses, productField} = this.state;

        let fieldLabelText = "";

        if (data.units === "mm") {
            fieldLabelText = "mm";
        }
        if (data.units === "percent") {
            fieldLabelText = "%";
        }
        return (
            <div className="item" key={data.id}>
                <div className="label">{data.label}{data.required && <span className="sign">*</span>}</div>
                <div className={`wrap-box ${data.units}`}>
                    <input
                        value={productField[data.id] ? productField[data.id] : ''}
                        id={data.id}
                        min={data.min}
                        max={data.max}
                        type={data.fieldType}
                        name={data.id}
                        className={errorClasses[[data.id]]}
                        onChange={this.handleChange}
                        placeholder={data.min ? `Min:${data.min}${fieldLabelText} - Max:${data.max}${fieldLabelText}` : ''}
                        data-unit={fieldLabelText}
                    />
                    {fieldLabelText ? (
                        <div className="field-label">{fieldLabelText}</div>
                    ) : (
                        ""
                    )}
                    <div className="invalid-feedback">{errors[[data.id]]}</div>
                </div>
            </div>
        );
    }

    drawCylinderField(data) {
        const {errors, errorClasses, productField} = this.state;

        let fieldLabelText = "mm";

        if (data.units === "mm") {
            fieldLabelText = "mm";
        }
        if (data.units === "percent") {
            fieldLabelText = "%";
        }
        return (
            <div className="item" key={data.id}>
                <div className="label"><span className="name">{data.label}</span> {data.required ? (
                    <span className="sign">*</span>) : ''}</div>
                <div className={`wrap-box ${fieldLabelText}`}>
                    <InputMask

                        id={data.id}
                        min={data.min}
                        max={data.max}
                        type={'text'}
                        data-type={data.fieldType}
                        name={data.id}
                        className={errorClasses[[data.id]]}
                        data-unit={fieldLabelText}
                        value={productField[data.id] ? productField[data.id].toString() : ''}
                        mask="9 9 9"
                        // maskchar= '_'
                        maskplaceholder="_"
                        onChange={this.handleChange}
                    />
                    {fieldLabelText ? (
                        <div className="field-label">{fieldLabelText}</div>
                    ) : (
                        ""
                    )}
                </div>
                <div className="invalid-feedback">{errors[[data.id]]}</div>
                {data.description !== '' && data.description !== undefined ? (
                    <div className="tooltip">
                        <svg className="question-icon" xmlns="http://www.w3.org/2000/svg" width="100" height="100"
                             viewBox="0 0 100 100">
                            <path
                                d="M-6494-54a49.689,49.689,0,0,1-19.463-3.929,50.069,50.069,0,0,1-8.493-4.61,50.362,50.362,0,0,1-7.4-6.105,50.354,50.354,0,0,1-6.105-7.4,49.954,49.954,0,0,1-4.609-8.493A49.679,49.679,0,0,1-6544-104a49.682,49.682,0,0,1,3.93-19.463,49.957,49.957,0,0,1,4.609-8.493,50.361,50.361,0,0,1,6.105-7.4,50.362,50.362,0,0,1,7.4-6.105,50.072,50.072,0,0,1,8.493-4.61A49.689,49.689,0,0,1-6494-154a49.688,49.688,0,0,1,19.462,3.929,50.013,50.013,0,0,1,8.493,4.61,50.382,50.382,0,0,1,7.4,6.105,50.416,50.416,0,0,1,6.105,7.4,50.031,50.031,0,0,1,4.609,8.493A49.688,49.688,0,0,1-6444-104a49.685,49.685,0,0,1-3.93,19.462,50.028,50.028,0,0,1-4.609,8.493,50.416,50.416,0,0,1-6.105,7.4,50.381,50.381,0,0,1-7.4,6.105,50.018,50.018,0,0,1-8.493,4.61A49.688,49.688,0,0,1-6494-54Zm-.4-31.651a6.4,6.4,0,0,0-4.606,1.732,5.873,5.873,0,0,0-1.8,4.4,5.822,5.822,0,0,0,1.761,4.341,6.4,6.4,0,0,0,4.646,1.712,6.412,6.412,0,0,0,4.647-1.712A5.826,5.826,0,0,0-6488-79.517a5.873,5.873,0,0,0-1.8-4.4A6.4,6.4,0,0,0-6494.4-85.65Zm.791-37.675a6,6,0,0,1,4.627,1.683,7.283,7.283,0,0,1,1.543,5.049,8.251,8.251,0,0,1-1.187,4.241,25.425,25.425,0,0,1-4.765,5.217,18.287,18.287,0,0,0-4.924,6.511,24.192,24.192,0,0,0-1.344,8.881h10.122l.158-2.43a11,11,0,0,1,3.441-6.81l3.2-3.066a26.4,26.4,0,0,0,5.26-6.671,14.161,14.161,0,0,0,1.5-6.392,17.714,17.714,0,0,0-1.156-6.606,12.939,12.939,0,0,0-3.47-4.923,15.214,15.214,0,0,0-5.566-3.062,24.975,24.975,0,0,0-7.444-1.021,23.885,23.885,0,0,0-7.429,1.075,15.839,15.839,0,0,0-5.68,3.226,13.9,13.9,0,0,0-3.623,5.127,18.328,18.328,0,0,0-1.261,6.78h11.467a7.179,7.179,0,0,1,1.839-5A6.264,6.264,0,0,1-6493.613-123.325Z"
                                transform="translate(6544 153.999)"
                                fill="#8f9293"/>
                        </svg>
                        <div class="left">
                            {data.description}
                        </div>
                    </div>
                ) : ('')}
            </div>
        );
    }

    drawTextField(data) {
        const {errors, errorClasses, productField} = this.state;
        return (
            <div className="item" key={data.id}>
                <div className="label">{data.label}{data.required && <span className="sign">*</span>}</div>
                <input
                    value={productField[data.id] ? productField[data.id] : ''}
                    onKeyPress={this.handleKeyPress}
                    type={data.fieldType}
                    name={data.id}
                    className={errorClasses[[data.id]]}
                    onChange={this.handleChange}
                />
                <div className="invalid-feedback">{errors[[data.id]]}</div>
            </div>
        );
    }


    drawDropDownField(data) {
        const {errors, errorClasses, productField} = this.state;
        return (
            <div className="item" key={data.id}>
                <div className="label">{data.label}{data.required && <span className="sign">*</span>}</div>
                <Select
                    classNamePrefix="react-select"
                    isMulti={false}
                    isSearchable={false}
                    value={[
                        data.optionsFields.find(
                            opt => opt.value === Number(productField[data.id])
                        )
                    ]}
                    name={data.id}
                    options={data.optionsFields}
                    onChange={(newValue, actionMeta) =>
                        this.dropdownChange(newValue, actionMeta)
                    }
                    data-refresh={data.need_reload}
                    className={errorClasses[data.id] + " react-select"}
                    placeholder="Please Select"
                />
                <div className="invalid-feedback">{errors[data.id]}</div>
            </div>
        );
    }

    drawSvg(data) {
        return SVG[data]
    }

    drawSwatchField(data) {
        const {errors, errorClasses} = this.state;
        return (
            <div className="option-row row-swatch clear" key={data.id}>
                <div className="label">{data.label}{data.required && <span className="sign">*</span>}</div>
                <div className="wrap-box">
                    {data.optionsFields.map((list, key) => {
                        return (
                            <div className="item" key={list.id}>
                                <input
                                    type="radio"
                                    id={list.id}
                                    defaultChecked={list.id === Number(data.value) ? true : false}
                                    name={data.id}
                                    onChange={this.handleSwatchChange}
                                    data-refresh={data.need_reload}
                                />
                                <label htmlFor={list.id}
                                       className={`custom-label-radio  ${list.image || list.sku ? "with-image" : ""}`}>

                                    {list.sku !== '' && list.sku !== undefined ?
                                        (
                                            <div dangerouslySetInnerHTML={{
                                                __html: this.drawSvg(list.sku)
                                            }}>
                                            </div>
                                        )
                                        : ('')}

                                    <div className="text">
                                        <div className="text-box">
                                            {list.title}
                                        </div>
                                    </div>
                                </label>
                            </div>
                        );
                    })}
                    <div className="invalid-feedback">{errors[[data.id]]}</div>
                </div>
            </div>
        );
    }


    drawDownloadableFileSwatchField(data) {
        // console.log(data);
        const {errors, hasAttachFile, mar, printingMethodUpload} = this.state;
        let description = data.description;
        description = description.replace(/(\r\n|\n|\r)/gm, "<br />");


        return (
            <div className={`option-row row-swatch clear`} key={data.id}>
                <div className="label"><span className="name">{data.label}</span> {data.required ? (
                    <span className="sign">*</span>) : ''}</div>
                <div className="wrap-box">
                    {data.optionsFields.map((list, key) => {
                        return (
                            <div className={`item ${list.image || list.sku ? "with-image" : ""}`} key={list.id}>
                                <input
                                    type="radio"
                                    id={list.id}
                                    defaultChecked={list.id === Number(data.value) ? true : false}
                                    name={data.id}
                                    onChange={this.handleSwatchChange}
                                    data-refresh={data.need_reload}
                                    data-downloadable={list.hasAttachFile}
                                />

                                <label
                                    htmlFor={list.id}
                                    className={`custom-label-radio  ${list.image || list.sku ? "with-image" : ""}`}
                                >
                                    {list.sku !== '' && list.sku !== undefined ?
                                        (
                                            <div dangerouslySetInnerHTML={{
                                                __html: this.drawSvg(list.sku)
                                            }}>
                                            </div>
                                        )
                                        :

                                        list.image ? <img src={list.image} alt={list.title}/> :
                                            (
                                                ""
                                            )
                                    }

                                    <div className="text">
                                        <div className="text-box">
                                            {list.title}
                                        </div>
                                    </div>
                                </label>
                            </div>
                        );
                    })}
                    <div className="invalid-feedback">{errors[[data.id]]}</div>

                    {data.description !== '' && data.description !== undefined ? (
                        <div className="tooltip">
                            <svg className="question-icon" xmlns="http://www.w3.org/2000/svg" width="100" height="100"
                                 viewBox="0 0 100 100">
                                <path
                                    d="M-6494-54a49.689,49.689,0,0,1-19.463-3.929,50.069,50.069,0,0,1-8.493-4.61,50.362,50.362,0,0,1-7.4-6.105,50.354,50.354,0,0,1-6.105-7.4,49.954,49.954,0,0,1-4.609-8.493A49.679,49.679,0,0,1-6544-104a49.682,49.682,0,0,1,3.93-19.463,49.957,49.957,0,0,1,4.609-8.493,50.361,50.361,0,0,1,6.105-7.4,50.362,50.362,0,0,1,7.4-6.105,50.072,50.072,0,0,1,8.493-4.61A49.689,49.689,0,0,1-6494-154a49.688,49.688,0,0,1,19.462,3.929,50.013,50.013,0,0,1,8.493,4.61,50.382,50.382,0,0,1,7.4,6.105,50.416,50.416,0,0,1,6.105,7.4,50.031,50.031,0,0,1,4.609,8.493A49.688,49.688,0,0,1-6444-104a49.685,49.685,0,0,1-3.93,19.462,50.028,50.028,0,0,1-4.609,8.493,50.416,50.416,0,0,1-6.105,7.4,50.381,50.381,0,0,1-7.4,6.105,50.018,50.018,0,0,1-8.493,4.61A49.688,49.688,0,0,1-6494-54Zm-.4-31.651a6.4,6.4,0,0,0-4.606,1.732,5.873,5.873,0,0,0-1.8,4.4,5.822,5.822,0,0,0,1.761,4.341,6.4,6.4,0,0,0,4.646,1.712,6.412,6.412,0,0,0,4.647-1.712A5.826,5.826,0,0,0-6488-79.517a5.873,5.873,0,0,0-1.8-4.4A6.4,6.4,0,0,0-6494.4-85.65Zm.791-37.675a6,6,0,0,1,4.627,1.683,7.283,7.283,0,0,1,1.543,5.049,8.251,8.251,0,0,1-1.187,4.241,25.425,25.425,0,0,1-4.765,5.217,18.287,18.287,0,0,0-4.924,6.511,24.192,24.192,0,0,0-1.344,8.881h10.122l.158-2.43a11,11,0,0,1,3.441-6.81l3.2-3.066a26.4,26.4,0,0,0,5.26-6.671,14.161,14.161,0,0,0,1.5-6.392,17.714,17.714,0,0,0-1.156-6.606,12.939,12.939,0,0,0-3.47-4.923,15.214,15.214,0,0,0-5.566-3.062,24.975,24.975,0,0,0-7.444-1.021,23.885,23.885,0,0,0-7.429,1.075,15.839,15.839,0,0,0-5.68,3.226,13.9,13.9,0,0,0-3.623,5.127,18.328,18.328,0,0,0-1.261,6.78h11.467a7.179,7.179,0,0,1,1.839-5A6.264,6.264,0,0,1-6493.613-123.325Z"
                                    transform="translate(6544 153.999)"
                                    fill="#8f9293"/>
                            </svg>
                            <div className="left" dangerouslySetInnerHTML={{
                                __html: description
                            }}>
                            </div>
                        </div>
                    ) : ('')}
                </div>
            </div>


        );
    }

    drawTextAreaField(data) {
        const {errors, errorClasses, productField} = this.state;
        return (
            <div className="option-row" key={data.id}>
                <div className="item" key={data.id}>
                    <div className="label">{data.label}{data.required && <span className="sign">*</span>}</div>
                    <textarea
                        value={productField[data.id]}
                        onKeyPress={this.handleKeyPress}
                        name={data.id}
                        className={errorClasses[[data.id]]}
                        onChange={this.handleChange}
                    ></textarea>
                    <div className="invalid-feedback">{errors[[data.id]]}</div>
                </div>
            </div>
        );
    }

    handleOpen() {
        this.setState({open: true});
    }

    handleClose() {
        this.setState({open: false});
        this.props.setOpenOption(false)
    }

    addProduct() {
        let {productCheckBoxList, errors} = this.state,
            currentComponent = this;

        if (this.handleValidation()) {
            for (let [key, value] of Object.entries(productCheckBoxList)) {

                if (Object.values(productCheckBoxList[key]).indexOf(true) === -1) {
                    errors[key] = 'Pleas select at last one item';
                    currentComponent.setState({
                        errors,
                    });
                    return false;
                }
            }
            this.calculatePrice();
            this.props.orderProduct(this.state.orderProductData);
            this.setState({open: false});
            this.props.setOpenOption(false)
            this.props.saveProduct(true)
        }
    }

    financial(x) {
        return Number.parseFloat(x).toFixed(2);
    }


    componentWillUnmount() {

    }


    handleChange(e) {
        let _this = e.target;
        let {errors, errorClasses} = this.state;
        const name = _this.name;
        const type = _this.type;
        let value = _this.value;

        const productField = this.state.productField;
        productField[name] = value;
        this.setState({
            productField,
            productTemplate: ""
        });
        if (type === 'cylinder') {
            value = value.replace(/[()\s]/g, '');
        }
        if (type === "number") {
            const fieldMin = parseFloat(_this.min);
            const fieldMax = parseFloat(_this.max);
            value = parseFloat(value);
            let unitText = _this.getAttribute("data-unit");
            if (value < fieldMin) {
                errors[name] = `Minimum must be ${fieldMin}${unitText}`;
                errorClasses[name] = "is-invalid";

                this.setState({
                    errors,
                    errorClasses
                });
            } else if (value > fieldMax) {
                errors[name] = `Maximum must be ${fieldMax}${unitText}`;
                errorClasses[name] = "is-invalid";

                this.setState({
                    errors,
                    errorClasses
                });
            } else {
                errors[name] = "";
                errorClasses[name] = "";
                this.setState({
                    errors,
                    errorClasses
                });
                if (!isNaN(value)) {
                    if (value !== undefined && value !== "") {
                        this.calculatePrice();
                    }
                }
            }
        }
    }

    handleSwatchChange(e) {
        const name = e.target.name;
        const productField = this.state.productField;
        productField[name] = e.target.id;
        let dataReload = e.target.getAttribute('data-refresh')

        if (dataReload === 'true') {
            this.setState({
                productField,
                productTemplate: ""
            });
            this.getProductChildren();

        } else {
            this.setState(
                {
                    productField,
                    productTemplate: ""
                },
                () => {
                    this.calculatePrice();
                }
            );
        }
    }

    handleCheckBoxChange(e) {
        const name = e.target.name;
        const checkBoxList = this.state.checkBoxList;
        const productCheckBoxList = this.state.productCheckBoxList;
        const errors = this.state.errors;
        errors[name] = ''

        let dataReload = e.target.getAttribute('data-refresh')
        if (e.target.checked) {
            productCheckBoxList[name][e.target.id] = true;
        } else {
            productCheckBoxList[name][e.target.id] = false;
        }
        if (dataReload === 'true') {
            this.setState({
                productCheckBoxList,
                productTemplate: "",
                errors
            });
            this.getProductChildren();

        } else {
            this.setState(
                {
                    productCheckBoxList,
                    productTemplate: "",
                    errors
                },
                () => {
                    this.calculatePrice();
                }
            );
        }
    }

    dropdownChange(val, name) {
        const productField = this.state.productField;
        let selectedOptionValue = val.value;
        let field = document.getElementsByName(`${name.name}`);

        let result = this.state.productOptions.filter(obj => {
            return obj.id === name.name;
        });

        let fieldName = name.name;

        if (result[0].need_reload === true) {
            productField[fieldName] = `${selectedOptionValue}`;
            this.setState({
                productField,
                productTemplate: ""
            });
            this.getProductChildren();
        } else {
            if (val.pdf_file !== undefined && val.pdf_file !== "") {
                if (field[0].nextSibling) {
                    field[0].nextSibling.remove();
                }
                let pdfUrl = val.pdf_file;
                if (!field[0].nextSibling) {
                    let html = ` <a target="_blank" href="${pdfUrl}">
        <svg className="pdf-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 681 679.28">
        <g>
        <g>
        <path className="cls-1" d="M116.61,181.54V53.31A36.31,36.31,0,0,1,152.92,17H627.69A36.31,36.31,0,0,1,664,53.31V626a36.31,36.31,0,0,1-36.31,36.31H152.92A36.31,36.31,0,0,1,116.61,626V499.54M584,463.85V217.22a35.69,35.69,0,0,0-35.69-35.68H52.69A35.69,35.69,0,0,0,17,217.22V463.85a35.69,35.69,0,0,0,35.69,35.69H548.31A35.69,35.69,0,0,0,584,463.85ZM103,423.54V262.77M269.2,423.54V262.77m237.8,0H436V423.54M103,357.73h36.52A47.48,47.48,0,0,0,187,310.25h0a47.48,47.48,0,0,0-47.48-47.48H103M269.2,423.54h33.13a52.31,52.31,0,0,0,52.31-52.31V315.07a52.3,52.3,0,0,0-52.31-52.3H269.2M496,342H436"/>
        </g>
        </g>
        </svg>
        </a> `;
                    let div = document.createElement("div");
                    div.innerHTML = html;
                    div.classList.add("option-pdf");
                    field[0].parentNode.appendChild(div);
                }
            } else {
                if (field[0].nextSibling) {
                    field[0].nextSibling.remove();
                }
            }
            productField[fieldName] = `${selectedOptionValue}`;
            this.setState(
                {
                    productField,
                    productTemplate: ""
                },
                () => {
                    this.calculatePrice();
                }
            );
        }
    }

    calculatePrice = async () => {
        let requestUrl = `/calcprice`;
        let currentComponent = this;
        let {productId, productName, productField, productCheckBoxList} = this.state;
        var selected = {};
        this.props.productPrice(false);
        this.props.product(false);
        productField.map((item, key) => {
            selected[key] = item;
        });
        Object.keys(productCheckBoxList).map(function (key, item) {
            return selected[key] = productCheckBoxList[key]
        })
        const queryParams = {
            productId: productId,
            selected: JSON.stringify(selected)
        };
        const productData = {
            id: productId,
            optionsJson: JSON.stringify(selected)
        };
        // queryParams.productId = productId;
        // queryParams.selected = JSON.stringify(productField);
        let res = await axios.post(apiUrl + requestUrl, queryParams, {
            headers: {Authorization: authHeader().Authorization}
        });

        let {data} = res;
        if (data.error === null) {
            if (data.payload.price > 0) {

                this.props.productPrice(data.payload);
                this.props.product(productData);
                let orderProductData = {
                    product: {
                        id: productId,
                        name: productName,
                    },
                    price: data.payload.price,
                    discount: data.payload.salePrice,
                    optionsJson: queryParams.selected,
                }
                this.setState(
                    {
                        price: data.payload.price,
                        discount: data.payload.salePrice,
                        salePrice: data.payload.price - data.payload.salePrice,
                        orderProductData: orderProductData,
                    }
                );
            }
        }
    };

    templateDropdownChange(val, name) {
        // const productTemplate = this.state.productTemplate;
        let {templateOptions} = this.state;

        let selectedOptionValue = val.value;
        let checkIfExist = templateOptions.find(
            opt => opt.value === Number(selectedOptionValue)
        );
        if (checkIfExist !== undefined) {
            this.setState(
                {
                    productTemplate: selectedOptionValue,
                    errors: {},
                    errorClasses: {}
                },
                () => {
                    this.getTemplateData(selectedOptionValue);
                }
            );
        }
    }


    getTemplateData = async templateId => {
        let requestUrl = `/templates/getById/${templateId}`;
        let currentComponent = this;

        let res = await axios.get(apiUrl + requestUrl, {
            headers: {Authorization: authHeader().Authorization}
        });

        let {data} = res;
        if (data.error === null) {
            let optionJson = data.payload.optionsJson;
            this.getProductChildren(optionJson);
        }
    };

    componentWillMount() {

        this.setState({
            open: true
        })

    }

    onRequestClose() {
        this.handleClose();
    }

    render() {

        const {
            productOptions,
            productId,
            optionsJson,
            errors,
            errorClasses,
            productTemplate,
            templateOptions,
            templateField,
        } = this.state;

        if (templateField) {
        }
        const options = [
            {value: "template1", label: "Template 1"},
            {value: "template2", label: "Template 2"},
            {value: "template3", label: "Template 3"},
            {value: "template4", label: "Template 4"},
            {value: "template5", label: "Template 5"}
        ];
        return (
            <Dialog className='custom-style' title="Choose Options"
                    open={this.state.open}
                // onClose={this.handleClose}
            >
                <DialogTitle id="simple-dialog-title">Choose Options</DialogTitle>
                <React.Fragment>

                    <div className="option-box">
                        <div id="loader" className="loader circle">
                            <svg
                                width="200px"
                                height="200px"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 100 100"
                                preserveAspectRatio="xMidYMid"
                                className="lds-rolling"
                            >
                                <circle
                                    cx="50"
                                    cy="50"
                                    fill="none"
                                    ng-attr-stroke="{{config.color}}"
                                    ng-attr-stroke-width="{{config.width}}"
                                    ng-attr-r="{{config.radius}}"
                                    ng-attr-stroke-dasharray="{{config.dasharray}}"
                                    stroke="#B4B000"
                                    strokeWidth="2"
                                    r="30"
                                    strokeDasharray="141.37166941154067 49.12388980384689"
                                    transform="rotate(159.346 50 50)"
                                >
                                    <animateTransform
                                        attributeName="transform"
                                        type="rotate"
                                        calcMode="linear"
                                        values="0 50 50;360 50 50"
                                        keyTimes="0;1"
                                        dur="1.4s"
                                        begin="0s"
                                        repeatCount="indefinite"
                                    ></animateTransform>
                                </circle>
                            </svg>
                        </div>
                        {templateField ? (
                            <div className="option-row">
                                <div className="item">
                                    <div className="label">Product Template</div>
                                    <Select
                                        classNamePrefix="react-select"
                                        isMulti={false}
                                        isSearchable={false}
                                        value={[
                                            templateOptions.find(
                                                opt => opt.value === Number(productTemplate)
                                            )
                                        ]}
                                        name="productTemplate"
                                        options={templateOptions}
                                        onChange={(newValue, actionMeta) =>
                                            this.templateDropdownChange(newValue, actionMeta)
                                        }
                                        className={errorClasses.productTemplate + " react-select"}
                                        placeholder="Please Select Template"
                                    />
                                    <div className="invalid-feedback">{errors.productTemplate}</div>
                                </div>
                            </div>
                        ) : (
                            ""
                        )}

                        {this.drawOptions()}
                        <React.Fragment>
                            {/* <div className="option-row">
                <div className="item">
                  <input
                      autoComplete="new-saveAsTemplate"
                      className="custom-checkbox"
                      id="saveAsTemplate"
                      onChange={this.handelChecked}
                      type="checkbox"
                      name="saveAsTemplate"
                  />
                  <label htmlFor="saveAsTemplate" className="custom-lable">
                    Save as template
                  </label>
                </div>
            </div> */}
                            <div className="option-row row-half clear">
                                <div className="item">
                                    <div className="label">Product Price</div>
                                    <input value={this.financial(this.state.price)} type="text" disabled/>
                                </div>
                                <div className="item">
                                    <div className="label">Sale Price</div>
                                    <input value={this.financial(this.state.salePrice)} type="text" disabled/>
                                </div>
                            </div>

                        </React.Fragment>
                        <Button variant="contained" color="primary" className="mr-3"
                                onClick={this.addProduct}>Save</Button>
                        <Button variant="outlined" color="secondary" onClick={this.handleClose}>Close</Button>

                    </div>
                </React.Fragment>
            </Dialog>
        );
    }
}

export default ProductOptionsPopup;
