import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import axios from "axios";
import authHeader from "./_helpers/authHeader";
import Fingerprint2 from "fingerprintjs2";

const apiUrl = process.env.REACT_APP_API_URL;

let currentToken = localStorage.getItem('token');
let options = {};

let fingerPrint = localStorage.getItem('fingerPrint');

Fingerprint2.get(options, function(components) {
    let values = components.map(function(component) {
        return component.value;
    });
    let hashedToken = Fingerprint2.x64hash128(values.join(""), 31);

    if(!fingerPrint && currentToken) {
        localStorage.setItem('fingerPrint' , hashedToken)
    }
});

function isPushNotificationSupported() {
    return "serviceWorker" in navigator && "PushManager" in window;
}

function registerServiceWorker() {
    return navigator.serviceWorker.register("service-worker.js");
}
async function createNotificationSubscription(payload) {
    const serviceWorker = await navigator.serviceWorker.ready;
    return await serviceWorker.pushManager.subscribe({
        userVisibleOnly: true,
        // applicationServerKey: pushServerPublicKey
    });
}



if (isPushNotificationSupported() && currentToken) {
    window.firebase.initializeApp({
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_AUTH_DOMAIN,
        databaseURL: process.env.REACT_APP_DATA_BASE_URL,
        projectId: process.env.REACT_APP_PROJECT_ID,
        storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_APP_ID,
        measurementId: process.env.REACT_APP_MEASUREMENT_ID
    });

    const messaging = window.firebase.messaging();


    messaging.onTokenRefresh(() => {
        messaging.getToken().then((refreshedToken) => {
            console.log('Token refreshed.');
            setTokenSentToServer(false);
            sendTokenToServer(refreshedToken);
            resetUI();
        }).catch((err) => {
            console.log('Unable to retrieve refreshed token ', err);
        });
    });


    messaging.onMessage((payload) => {
        console.log('Message received. ', payload);

        console.log(payload);
        appendMessage(payload);
    });


    function resetUI() {
        messaging.getToken().then((currentToken) => {
            if (currentToken) {
                if (!isTokenSentToServer()) {
                    sendTokenToServer(currentToken);
                }
            } else {
                console.log('No Instance ID token available. Request permission to generate one.');
                setTokenSentToServer(false);
            }
        }).catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
            setTokenSentToServer(false);
        });
    }

    async function sendTokenToServer(currentToken) {
        if (!isTokenSentToServer()) {
            console.log('Sending token to server...');

            let requestUrl = `/pushnotifications/update`;

            let memberData = {
                deviceId:localStorage.getItem('fingerPrint'),
                token:currentToken
            };


            let res = await axios.post(apiUrl + requestUrl, memberData, {
                headers: authHeader()
            });
            let { data } = res;
            if (data.error === null) {
                setTokenSentToServer(true);
            }
        } else {
            console.log('Token already sent to server so won\'t send it again ' +
                'unless it changes');
        }
    }

    function isTokenSentToServer() {
        return window.localStorage.getItem('sentToServer') === '1';
    }

    function setTokenSentToServer(sent) {
        window.localStorage.setItem('sentToServer', sent ? '1' : '0');
    }

    function appendMessage(payload) {
        let title = payload.notification.title !== undefined ? payload.notification.title : ''
        var notification = new Notification(title,payload.notification);
    }


    resetUI();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
