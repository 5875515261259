import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import OptionsPopup from "../orders/productOptionsPopup";
import {Table, TableBody, TableCell, TableRow, TableHead} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import TablePagination from '@material-ui/core/TablePagination';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import Typography from '@material-ui/core/Typography';
import {blue} from '@material-ui/core/colors';

import axios from "axios";
import authHeader from "../_helpers/authHeader";

const useStyles = makeStyles(theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));

function SimpleDialog(props) {
    const classes = useStyles();
    const {onClose, selectedValue, open, openOption, productId, productName} = props;
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    var [page, setPage] = React.useState(0);
    var [products, setProducts] = React.useState({});
    var [productsCount, setProductsCount] = React.useState(0);
    var [filterName, setFilterName] = React.useState('');
    const prevProductName = useRef();


    React.useEffect(() => {
        let query = {};
        if (filterName !== "") {
            query.name = filterName;
        }
        if (filterName !== prevProductName.current) {
            setPage(0);
        }
        prevProductName.current = filterName;

        let limit = 10;
        let startNumber = page * limit;
        let endNumber = page * limit + limit;
        query._start = startNumber
        query._end = endNumber
        query._order = "DESC"
        query._sort = "id"


        const fetchData = async () => {
            const result = await axios.get(`${process.env.REACT_APP_API_URL}/products`,
                {
                    headers: {Authorization: authHeader().Authorization},
                    params: query
                }).then((response) => {
                if (response.status === 200 && response.data.length > 0) {
                    setProducts(response.data)
                    let count = response.headers['x-total-count'];
                    setProductsCount(parseInt(count));
                    return response;
                } else {
                    setProducts({})
                    setProductsCount(0);
                }
            });

        }
        fetchData();
    }, [page, filterName]);


    React.useEffect(() => {
            if (props.open) {
                props.onChangeValue(productId, productName, selectedValue);
            }

        },
        //  [props.productId, productName, props.selectedValue ,productId, selectedValue])
        //  [props.productId, productName, props.selectedValue, props, productId, selectedValue])
        [props.productId, productName, props.selectedValue, props.open])


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }
    const handleClose = () => {

        onClose(selectedValue, productId, productName);
    };

    const handleListItemClick = (productId, productName) => {
        onClose(productId, productName);
    };
    const filterChange = (event, value) => {
        setFilterName(event.target.value);
    }

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">Select product</DialogTitle>

            <List>
                {(products.length > 0) ?
                    <div>
                        <div>
                            <TextField
                                id="outlined-basic"
                                className={classes.textField}
                                label="Product Name"
                                margin="normal"
                                variant="outlined"
                                value={filterName}
                                onChange={filterChange}
                            />
                        </div>

                        <Table className={classes.table} aria-label="simple table">
                            <TableBody>
                                {products.map(function (product) {
                                    return (
                                        <TableRow key={product.id}
                                                  onClick={() => handleListItemClick(product.id, product.name)}
                                                  key={product.id}>
                                            <TableCell align="right">{product.id}</TableCell>
                                            <TableCell align="right">{product.name}</TableCell>
                                            <TableCell align="right">{product.url}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[10]}
                            component="div"
                            count={productsCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            backIconButtonProps={{
                                'aria-label': 'previous page',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'next page',
                            }}
                            onChangePage={handleChangePage}
                        />
                    </div>
                    :
                    <div>
                        <div>
                            <TextField
                                id="outlined-basic"
                                className={classes.textField}
                                label="Product Name"
                                margin="normal"
                                variant="outlined"
                                value={filterName}
                                onChange={filterChange}
                            />
                        </div>
                        Products not exist
                    </div>

                }

            </List>
        </Dialog>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    //selectedValue: PropTypes.string.isRequired,
    selectedValue: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.object.isRequired
    ]),
};

export default function SimpleDialogDemo(props) {
    const [open, setOpen] = React.useState(false);
    const [openOption, setOpenOption] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState(props.selectedValue);
    const [productId, setProductId] = React.useState(props.productId);
    const [productName, setProductName] = React.useState(props.productName);
    const [orderProduct, setOrderProduct] = React.useState(props.orderProduct);
    const [productPrice, setProductPrice] = React.useState(props.productPrice);
    const [product, setProduct] = React.useState([]);


    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleOptionsClickOpen = () => {
        setOpenOption(true);
    };
    const handleOptionsDelete = () => {
        setOrderProduct({});
        setProductPrice({});
        props.deleteProduct(true);
    };
    React.useEffect(() => {
            setSelectedValue('');
        },
        []);

    React.useEffect(() => {
            setProductPrice(props.productPrice);
            setOrderProduct(props.orderProduct);
        },
        [props.productPrice, props.orderProduct])

    const hendleOptionClose = () => {
        setOpenOption(false);

    }

    const hendleProductPrice = childData => {
        props.productPrice(childData);
        setProductPrice(childData)
    }

    const hendleOrderProduct = childData => {
        // props.productPrice(childData);
        setOrderProduct(childData)
    }

    const hendleProduct = childData => {
        props.product(childData);

        setProduct(childData)
    }
    const hendleSaveProduct = childData => {
        props.saveProduct(childData)
    }

    const handleClose = (productIdVal, productNameVal) => {
        if (productIdVal != '') {
            setOrderProduct({
                product: {
                    id: productIdVal,
                    name: productNameVal
                }

            });
        }
        setOpen(false);
        setOpenOption(false);

        setProductId(productIdVal);
        setProductName(productNameVal);
    };

    const financial = (x) => {
        if (x != undefined) {
            return Number.parseFloat(x).toFixed(2);
        }
        return x;
    }

    const classes = useStyles();
    return (
        <div>
            <div variant="subtitle1">
                {(Object.keys(orderProduct).length > 0) ?
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Product Id</TableCell>
                                <TableCell align="left">Product Name</TableCell>
                                <TableCell align="left">Product Price</TableCell>
                                <TableCell align="left">Discount</TableCell>
                                <TableCell align="left">Options</TableCell>
                                <TableCell align="left">Delete</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow key={orderProduct.product.id}>
                                <TableCell align="left">{orderProduct.product.id}</TableCell>
                                <TableCell align="left">{orderProduct.product.name}</TableCell>
                                <TableCell align="left">{financial(orderProduct.price)}</TableCell>
                                <TableCell align="left">{financial(orderProduct.discount)}</TableCell>
                                <TableCell align="left">
                                    <Button variant="outlined" color="primary" onClick={handleOptionsClickOpen} disabled={props.isDisabled}>
                                        Options
                                    </Button>
                                    {(openOption) ? <OptionsPopup title="Option" saveProduct={props => {
                                        hendleSaveProduct(props)
                                    }} orderProduct={hendleOrderProduct} productPrice={hendleProductPrice}
                                                                  product={hendleProduct}
                                                                  setOpenOption={hendleOptionClose}
                                                                  productId={orderProduct.product.id}
                                                                  productName={orderProduct.product.name}
                                                                  optionsJson={orderProduct.optionsJson}/> : null}
                                </TableCell>
                                <TableCell align="left">
                                    <Button variant="outlined" color="secondary" onClick={handleOptionsDelete} disabled={props.isDisabled}>
                                        Delete
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    : ''}
            </div>
            <br/>
            {(Object.keys(orderProduct).length === 0) ?
                <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                    {props.title}
                </Button>
                : ''}
            <SimpleDialog selectedValue={selectedValue} productId={productId} productName={productName}
                          onChangeValue={props.onChangeValue} open={open} onClose={handleClose}/>
        </div>
    );
}
