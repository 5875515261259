import React, { Component } from "react";
import  authHeader from '../_helpers/authHeader';
import Button from '@material-ui/core/Button';
import axios from "axios";
import io from 'socket.io-client';
import './style.css';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import CloseIcon from '@material-ui/icons/Close';
import { Scrollbars } from "react-custom-scrollbars";

const apiUrl = process.env.REACT_APP_API_URL;



class Messenger extends Component {

  constructor(props) {
    super(props);
    this.socket = {};
    this.state = {
      orderId:props.orderId,
      chatMembersList:[],
      chatHistory:[],
      endpoint:  process.env.REACT_APP_CHAT_URL,
      isChatOpen: false,
    };
    this.getChatMembers = this.getChatMembers.bind(this);
    this.getMessageSocket = this.getMessageSocket.bind(this);
    this.getChatHistory = this.getChatHistory.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChatToggle = this.handleChatToggle.bind(this);
  }
  scrollbars = React.createRef();

  _isMounted = false;
  getChatMembers = async e => {
    let requestUrl = `/chat/members/${this.state.orderId}`;
    let currentComponent = this;
    console.log('data', this);

    let res = await axios.get(apiUrl + requestUrl, {
      headers: authHeader()
    });
    let { data } = res;
    if (data.error === null) {
      currentComponent._isMounted = true;
      //   console.log(data);
      let chatMembersList = data.payload.memberNamesStr
      currentComponent.setState(
        {
          chatMembersList
        },
        () => {}
      );
    }
  }
  handleChange(event){
    const target = event.target;
    this.setState({message:target.value});
  }
  handleChatToggle(e) {
    this.setState({
      isChatOpen : !this.state.isChatOpen
    },()=>{
      this.scrollbars.current.scrollToBottom()
    })
  }
  getChatHistory = async e => {
    let requestUrl = `/chat/messages/${this.state.orderId}`;

    let res = await axios.get(apiUrl + requestUrl, {
      headers: authHeader()
    });

    let { data } = res;
    if (data.error === null) {
      this._isMounted = true;
      let chatHistory = data.payload
      this.setState(
        {
          chatHistory
        },
        () => {
          this.scrollbars.current.scrollToBottom()
        }
      );
    }
  }
  getMessageSocket() {
    const { endpoint, orderId } = this.state;
    console.log(this.state)
    let token = localStorage.getItem("token");
    this._isMounted = true;
    this.socket = io(endpoint , {
      query: `token=${token}`,
      path: '/orderchat',
      transports: ['websocket']
    });
    console.log(endpoint,orderId)
    this.socket.emit('join', orderId);

    this.socket.on('message', (msg)=>{
      console.log('msg',msg);
      if(msg !== undefined && msg !== '') {
        let {chatHistory} = this.state;
        chatHistory.push(msg)
        this.setState({
          chatHistory,
          message:''
        },
        () => {
          this.scrollbars.current.scrollToBottom()
        })
      }

      console.log('message', msg)
    })
  }
  componentDidMount() {
    this.getChatMembers();
    this.getChatHistory();
    this.getMessageSocket()
  }
  sendMessage(msg){
    console.log('sendMessage',msg);
    this.socket.emit('message', {message:msg});
    let input = document.getElementById('chat-input')
    input.style.cssText = 'height:40px';
  }

  onEnterPress = (e) => {
    let element = e.target
    if(e.keyCode == 13 && e.shiftKey == false ) {
      e.preventDefault();
      if(element.value !== '' && element.value.replace(/\s/g, '').length) {
        this.sendMessage(element.value)
      }
    }else if (e.keyCode == 13 && e.shiftKey == true ){
      setTimeout(function(){
        element.style.cssText = 'height:auto; padding:0';
        // for box-sizing other than "content-box" use:
        // el.style.cssText = '-moz-box-sizing:content-box';
        element.style.cssText = 'height:' + element.scrollHeight + 'px';
      },0);
    }else if (element.value === '') {
      element.style.cssText = 'height:40px';
    }
  }
  componentWillUnmount() {}
  renderThumb({ style, ...props }) {
    const thumbStyle = {
      right: 0,
      backgroundColor: `rgba(0, 0, 0, 0.2)`,
      cursor: `pointer`,
      borderRadius: `inherit`
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  }
  handleUpdate(values) {
    const { top } = values;
    this.setState({ top });
  }
  render() {
    
    let {chatMembersList,chatHistory,message} = this.state;
    console.log(chatHistory)
    return (
      <div className="sticky-box-container chat-box">
        <div className={`sticky-box ${this.state.isChatOpen ? 'open' : ''}`}>
          <div className="chat-wrap">
            {this._isMounted ? (
                <React.Fragment>
                  <div className="chat-header">
                    <div className="chat-members-names">
                      {chatMembersList}
                    </div>
                  </div>
                  <div className="wrap-box">
                  <Scrollbars ref={this.scrollbars} autoHeight autoHeightMax={400} renderThumbVertical={this.renderThumb} className="chat-area">
                    <div className="chat-content">

                      {chatHistory.length > 0 ? (
                          chatHistory.map((item, key) => {
                            return (
                                <div className={`message-item ${(item.isAdmin === true ) ? '' : 'from'}`} key={key}>
                                  <div className="message-header">
                                    <div className="name">{item.user.firstname}</div>
                                    <div className="date">{item.createdAt}</div>
                                  </div>
                                  <div className="message-content">
                                    {item.message}
                                  </div>
                                </div>
                            )
                          })
                      ):(
                          <div className="no-result-box">
                            <div className="text">No Messeges for this order</div>
                          </div>
                      )}

                    </div>
                  </Scrollbars>

                    <div className="field-wrap">
                      <textarea id="chat-input" rows="5" placeholder="Type a message…" value={message} onChange={this.handleChange} onKeyDown={this.onEnterPress} />
                    </div>
                  </div>
                </React.Fragment>
            ):('')}

          </div>
          <div className="text-right mt-3">
            <Button className="chat-button" onClick={this.handleChatToggle}>
              {this.state.isChatOpen ? <CloseIcon /> : <MailOutlineIcon />}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default Messenger;
