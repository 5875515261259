// in posts.js
import React from 'react';
import './style.css';
import { 
    List, 
    SimpleShowLayout,
    Show,
    Datagrid, 
    Edit, 
    Create, 
    SimpleForm, 
    DateField, 
    Filter,
    SelectInput,
    EmailField,
    TextField, 
    EditButton, 
    ShowButton,
    DisabledInput, 
    ReferenceInput,
    TextInput, 
    required,
    minLength,
    email
 } from 'react-admin';
 const confirmPassword = (value,allValues) =>{
    if(value !== allValues.password){
        return "Passwords mismatch";
    }
};

const validateFirstName = [required(), minLength(2)];
const validateLastName = [required(), minLength(2)];
const validatePassword = [required(), minLength(8)];
const validateRePassword = [required(), minLength(8),confirmPassword];
const validateEmail = [required(),email()];
const validateUserGroup = [required()];
//import BookIcon from '@material-ui/core/svg-icons/action/book';
//export const PostIcon = BookIcon;
const accessControl = !JSON.parse(localStorage.getItem('permissions')) ? {} : JSON.parse(localStorage.getItem('permissions'));
let devPermission = JSON.parse(localStorage.getItem('devPermission'))
    let deleteRow = false
    if(devPermission !== null && devPermission.deleteRow === true) {
        deleteRow = devPermission.deleteRow;
    }
const AdminUserFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Email" source="email" alwaysOn />
        {accessControl['admingroups'] && accessControl['admingroups'].includes('list')  ?
            <ReferenceInput label="Admin User Group" source="groupId" reference="admingroups">
                <SelectInput optionText="name" optionValue="id" />
            </ReferenceInput>
            : 
            null
        }
        <TextInput label="First Name" source="firstname" />
        <TextInput label="Last Name" source="lastname" />
    </Filter>
);
export const AdminUserList = (props) => {
    return (
    <List filters={<AdminUserFilter/>} {...props} bulkActions={deleteRow === true ? '' : false} title="Admin Users" >
        <Datagrid>
            <TextField source="id" />
            <EmailField source="email" />
            <TextField source="firstname" />
            <TextField source="lastname" />
            <TextField source="group.name" />
            <DateField source="createdAt" />
            <EditButton basePath="/adminusers" />
            <ShowButton basePath="/adminusers" />
        </Datagrid>
    </List>
)};
const UserName = ({ record }) => {
    return <span>Admin User {record ? `"${record.firstname} ${record.lastname}"` : ''}</span>;
};
export const AdminUserEdit = (props) => (
    <Edit title={<UserName />} {...props}>
        <SimpleForm className="admin-users-edit ra-input-bordered content-inner-pt content-inner-pb flexible-card-child column-3">
            <DisabledInput source="id" />
            <TextInput source="firstname" validate={validateFirstName} />
            <TextInput source="lastname" validate={validateLastName} />
            <TextInput source="email" type="email" validate={validateEmail} />
            {accessControl['admingroups'] && accessControl['admingroups'].includes('list')  ?
                <ReferenceInput label="Admin User Group" source="groupId" reference="admingroups" validate={validateUserGroup}>
                    <SelectInput optionText="name" optionValue="id"  />
                </ReferenceInput>
                :
                <TextField source="group.name"/>
            }
            <DisabledInput  source="createdAt" />
        </SimpleForm>
    </Edit>
);
export const AdminUserShow = (props) => (
    <Show title={<UserName />} {...props}>
        <SimpleShowLayout className="list-show ra-field-bordered flexible-child ra-field-column-3">
            <TextField source="id" />
            <TextField source="firstname" />
            <TextField source="lastname" />
            <TextField source="email" />
            <TextField source="group.name" />
            <DateField label="Publication date" source="createdAt" />
        </SimpleShowLayout>
    </Show>
);

export const AdminUserCreate = (props) => (
    <Create title="Create a Admin User" {...props}>
        <SimpleForm className="admin-users-create ra-input-bordered content-inner-pt content-inner-pb flexible-card-child column-3">
            <TextInput source="firstname" validate={validateFirstName}/>
            <TextInput source="lastname" validate={validateLastName}/>
            <TextInput source="email" type="email" validate={validateEmail}/>
            <TextInput source="password" type="password" validate={validatePassword}/>
            <ReferenceInput label="Admin User Group" source="groupId" reference="admingroups" validate={validateUserGroup}>
                <SelectInput optionText="name" optionValue="id"/>
            </ReferenceInput>
            <TextInput source="repassword" label="Repeat Password" type="password" validate={validateRePassword}/>
        </SimpleForm>
    </Create>
);