import React from "react";
import './style.css';
import {
  List,
  Show,
  SimpleShowLayout,
  ReferenceInput,
  Datagrid,
  DateField,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  ShowButton,
  DeleteButton,
  DisabledInput,
  BooleanField,
  BooleanInput,
  TextInput,
  SelectInput,
  LongTextInput,
  RichTextField,
  ImageField,
  ImageInput,
  Filter,
  Button,
  TabbedForm,
  FormTab,
  ReferenceManyField,
  required
} from "react-admin";
import RichTextInput from "ra-input-rich-text";

const validateName = [required()];
const validateKey = [required()];
const validateFile = [required()];
const validateDescription = [required()];
const validateStatus = [required()];
const validateVisibility = [required()];
const validateUrl = [required()];
const CategoriesFilter = props => (
  <Filter {...props}>
    <TextInput label="Name" source="name" alwaysOn />
    <TextInput label="Key" source="key" />
  </Filter>
);
export const CategoriesList = props => (
  <List filters={<CategoriesFilter />} {...props}>
    <Datagrid>
      

      <TextField source="id" />
      <TextField source="name" />
      <TextField source="key" />
      <TextField source="status" />
      <EditButton basePath="/categories" />
      <ShowButton basePath="/categories" />
      {/*<DeleteButton basePath="/products" />*/}
    </Datagrid>
  </List>
);

const CategoryTitle = ({ record }) => {
  return <span>
    Category {record ? `"${record.name}"` : ""}
  </span>;
};
function formatLogo(value) {
  if (!value || typeof value === "string") {
    // Value is null or the url string from the backend, wrap it in an object so the form input can handle it
    return { url: value };
    alert(true)
  } else {
    // Else a new image is selected which results in a value object already having a preview link under the url key
    return value;
    alert(false)
  }
}

export const CategoriesEdit = props => (
  <Edit title={<CategoryTitle />} {...props}>
    <TabbedForm className="category-edit ra-input-bordered flexible-mui-span-child content-inner-pt content-inner-pb column-3">
      <FormTab label="summary" >
        <DisabledInput source="id" />
        <TextInput source="name" validate={validateName} />
        <TextInput source="key" validate={validateUrl} />
        <TextInput source="meta_title" />
        <LongTextInput source="short_description" />
        <LongTextInput source="meta_description" />
        <SelectInput
          source="status"
          validate={validateStatus}
          choices={[
            { id: "active", name: "Active" },
            { id: "inactive", name: "Inactive" }
          ]}
        />
        <SelectInput
            source="visibility"
            validate={validateVisibility}
            choices={[
              { id: "yes", name: "Yes" },
              { id: "no", name: "No" }
            ]}
        />
        <RichTextInput source="description" validate={validateDescription} className="description-text" />
        <RichTextInput source="gallery_description" className="description-text" />
      </FormTab>
      <FormTab label="Galleries" path="body" contentClassName="gallery-wrap-box">
        <ImageInput multiple source="pictures" label="Upload" accept="image/*">
          <ImageField source="src" title="title" />
        </ImageInput>
        <ReferenceManyField
          reference="categorygalleries"
          target="category_id"
          addLabel={false}
          className="gallery-image-box"
        >
          <Datagrid>
            <ImageField source="file" src="file" label="Files" />
            <TextField source='GalleryType' />
            <EditButton basePath="/categorygalleries" className="gallery-action-btn" />
            <DeleteButton basePath="/categories" redirect={false} className="gallery-action-btn" />
          </Datagrid>
        </ReferenceManyField>
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const CategoriesCreate = props => (
  <Create title="Create Category" {...props}>
    <SimpleForm encType="multipart/form-data" className="category-create ra-input-bordered flexible-mui-child column-3">
      <DisabledInput source="id" />
      <ImageInput multiple source="pictures" label="Upload" accept="image/*">
        <ImageField source="src" title="title" />
      </ImageInput>
      <TextInput source="name" validate={validateName} />
      <TextInput source="key" validate={validateUrl} />
      <TextInput source="meta_title" />
      <LongTextInput source="short_description" />
      <LongTextInput source="meta_description" />
      <SelectInput
        source="status"
        validate={validateStatus}
        choices={[
          { id: "active", name: "Active" },
          { id: "inactive", name: "Inactive" }
        ]}
      />
      <SelectInput
          source="visibility"
          validate={validateVisibility}
          choices={[
            { id: "yes", name: "Yes" },
            { id: "no", name: "No" }
          ]}
      />
      <RichTextInput source="description" validate={validateDescription} className="description-text"/>
      <RichTextInput source="gallery_description" className="description-text" />
    </SimpleForm>
  </Create>
);

export const CategoriesShow = props => (
  <Show title={<CategoryTitle />} {...props}>
    <SimpleShowLayout className="category-show ra-field-bordered flexible-child ra-field-column-3">
      <TextField source="name" />
      <TextField source="key" />
      <DateField label="Publication date" source="createdAt" />
      <ImageField className="gallery-image-list" multiple source="galleries" src="file" />
    </SimpleShowLayout>
  </Show>
);
