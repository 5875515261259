import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import axios from "axios";
import authHeader from "../_helpers/authHeader";
const useStyles = makeStyles(theme => ({
    formControl: {
      margin: theme.spacing(1),
      width: "100%"
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    formField: {
        margin: 15
    }
  }));
export default function AddressesForm(props) {
    const classes = useStyles();
    const { value , onChangeValue } = props;
    const [address, setAddresses] = React.useState(props.value);
    const [countries, setCountries] = React.useState([]);
    React.useEffect(() => {
      let query = { _start: 0, _end: 50};
      axios.get(`${process.env.REACT_APP_API_URL}/countries`,
      {
          headers:{Authorization:authHeader().Authorization},
          params: query
      }).then((response) =>{
        //   console.log('countries ', response)
          if(response.status === 200){
              setCountries(response.data)
          }
      });
    }, []);
    React.useEffect( () => {
            let query = {id: props.addressId};
            axios.get(`${process.env.REACT_APP_API_URL}/addresses/${props.addressId}`,
            {
                headers:{Authorization:authHeader().Authorization},
                params: query
            }).then((response) =>{
                if(response.status === 200 && response.data !== null){
                    let addressObj = {
                        firstname: response.data.firstname,
                        middlename: response.data.middlename,
                        lastname: response.data.lastname,
                        company: response.data.company,
                        street: response.data.street,
                        city: response.data.city,
                        countryId: response.data.countryId,
                        countryCode: response.data.country.country_code,
                        region: response.data.region,
                        postcode: response.data.postcode,
                        telephone: response.data.telephone,
                        fax: response.data.fax
                    
                    };
                    if(value['sameAsBilling']  !== undefined){
                        addressObj.sameAsBilling = false
                    }
                    setAddresses(addressObj)
                    onChangeValue(addressObj)
                    // console.log("address ", address)
                }
            });
    }, [props.addressId]);
    React.useEffect( () => {
        setAddresses({sameAsBilling : props.value.sameAsBilling})
    }, [props.value.sameAsBilling])
    React.useEffect( () => {
        setAddresses(props.value)
    }, [props.value])
  return (
    <FormControl disabled={props.disabled} className={classes.formControl} value={value}  onChange={onChangeValue}>
            {
                address['sameAsBilling'] !== undefined  && 
                <FormControlLabel
                control={
                    <Checkbox
                        checked={address.sameAsBilling}
                        value={address.sameAsBilling}
                         //onChange={onChangeValue}
                        color="primary"
                        name="sameAsBilling"
                    />
                }
                
                label="Use same as billing"
                // name="sameAsBilling"
            />
            }
                
        <TextField disabled={props.disabled} label="First Name *" className={classes.formField} variant="outlined" name="firstname" value={address.firstname} />
        <TextField disabled={props.disabled} label="Middle Name" className={classes.formField} variant="outlined" name="middlename" value={address.middlename} />
        <TextField disabled={props.disabled} label="Last Name *" className={classes.formField} variant="outlined" name="lastname" value={address.lastname}/>
        <TextField disabled={props.disabled} label="Company" className={classes.formField} variant="outlined" name="company" value={address.company}/>
        <TextField disabled={props.disabled} label="Street Address" className={classes.formField} variant="outlined" name="street" value={address.street} />
        <TextField disabled={props.disabled} label="City *" className={classes.formField} variant="outlined" name="city" value={address.city}/>
        <NativeSelect disabled={props.disabled} label="Country *" className={classes.formField} labelId="demo-simple-select-label" 
             value={address.countryId}
             
             blabla="asdasd"
             inputProps={{
                name: 'countryId',
                id: 'uncontrolled-native',
                countryCode:address.country_code
              }}
        >
            {countries ? countries.map(function(country) {
                    return (
                        <option 
                            key={country.id}
                            value={country.id}
                            id={country.country_code}
                            countryCode={country.country_code}
                            label={country.country_name}

                        >{country.country_name}</option>
                    );
            }) : "" }
        </NativeSelect>

        
        <TextField disabled={props.disabled} label="State/ Province" className={classes.formField} variant="outlined" name="region"  value={address.region}/>
        <TextField disabled={props.disabled} label="ZIP / Postal code" className={classes.formField} variant="outlined" name="postcode" value={address.postcode} />
        <TextField disabled={props.disabled} label="Telephone*" className={classes.formField} variant="outlined" name="telephone" value={address.telephone}/>
        <TextField disabled={props.disabled} label="Fax" className={classes.formField} variant="outlined" name="fax" value={address.fax}/>
        <FormControlLabel 
                control={
                    <Checkbox
                    disabled={props.disabled}
                        checked={address.saveAsNewAddress}
                        value={address.saveAsNewAddress}
                        // onChange={onChangeValue}
                        color="primary"
                        name="saveAsNewAddress"
                    />
                }
                
                label="Save in address book"
                // name="sameAsBilling"
            />
    </FormControl>
  );
}


