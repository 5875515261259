import React, { Component } from 'react';

import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import { withStyles } from "@material-ui/core/styles";
import { Button, Link } from "react-admin";

const styles = {
  button: {
    marginTop: "1em",
  },
};

const EditCreditButton = withStyles(styles)(({ classes, record }) => (
  <Button
    className={classes.button}
    variant="raised"
    component={Link}
    to={{
      pathname: `/credits/edit/${record.id}`,
      // search: `?userId=${record.id}`,
    }}
    label="Edit Credit"
  >
    <ChatBubbleIcon />
  </Button>
));
export default EditCreditButton;
