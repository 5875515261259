import React, { Component } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
const useStyles = makeStyles(theme => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: 200,
      },
    },
  }));

let Weight = function Weight(props) {
    const classes = useStyles();
    const { value , onChangeValue } = props;

    return (
        <form className={classes.root} noValidate autoComplete="off">
            <TextField disabled={props.disabled} id="outlined-basic" label="Weight" type="number" variant="outlined"
                       value={value} onChange={onChangeValue} inputProps={{min:1}}/>
        </form>
    )
  };

  export default Weight