import axios from "axios";
import  authHeader  from '../_helpers/authHeader';
export const getTypes = () =>{
    return axios.get(process.env.REACT_APP_API_URL+'/productoptions/types',{headers:authHeader() });
}

export const getValidations = () =>{
    return axios.get(process.env.REACT_APP_API_URL+'/productoptions/validations',{headers:authHeader() });
}

export const getCalculationLogics = () =>{
    return axios.get(process.env.REACT_APP_API_URL+'/productoptions/calclogics',{headers:authHeader() });
}

export const getOptions = (id)=>{
   return axios.get(`${process.env.REACT_APP_API_URL}/productoptions/${id}`,{ headers:authHeader()});
}

export const deleteOption = (id) =>{
    return axios.delete(`${process.env.REACT_APP_API_URL}/productoptions/${id}`,{ headers:authHeader()}); 
}

export const deleteOptionField = (id) =>{
    return axios.delete(`${process.env.REACT_APP_API_URL}/productoptions/fields/${id}`,{ headers:authHeader()}); 
}
export const getAttributes = (id) =>{
    return axios.get(`${process.env.REACT_APP_API_URL}/productoptions/attributes`,{ headers:authHeader()}); 
}