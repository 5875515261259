import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';
import HelpIcon from '@material-ui/icons/Help';
import { green, red,orange } from '@material-ui/core/colors';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import axios from "axios";
import authHeader from "../_helpers/authHeader";
import ErrorDialog from "./errorDialog"
import ConfirmDialog from "./confirmDialog"
const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
  });
function ViewFiles(props) {

    const [confirmShow , showConfirmPopUp] = useState(false);
    const [fileVersionId , documentVersionId] = useState('');
    const [fileProjectId , projectId] = useState('');
    const classes = useStyles();
    const { value , orderId } = props;
    
    const handleDeleteFile = (versionID, projectID) => {
        let loader = document.getElementById('global-loader')

        loader.classList.add('show')
        axios.get(`${process.env.REACT_APP_API_URL}/orders/deleteFile/${versionID}`,
                {
                    headers:{Authorization:authHeader().Authorization, projectId:projectID}
                }).then(response => {
                    if(response.data.code === 200){
                       window.location.reload();
                    }else{
                       return  window.confirm('Something went wrong')
                    }
                })
                .catch(err => {return err})
    }
    
    const handleHideConfirmPopup = e => {
        showConfirmPopUp(false)
    }
    const deleteFile = (versionID, projectID) => {
        showConfirmPopUp(true)
        documentVersionId(versionID)
        projectId(projectID)
    
    }
    const handleChangeConfirmPopup = e => {
        showConfirmPopUp(false)
        let loader = document.getElementById('global-loader');

        loader.classList.add('withGrayOpacity')
        handleDeleteFile(fileVersionId, fileProjectId)
    }
    
    return (
        <>
            <ConfirmDialog open={confirmShow} text={'Are you sure?'} onHandleClose={handleHideConfirmPopup} onChangeValue = {handleChangeConfirmPopup} />
            <Table  stickyHeader component={Paper} className={`${classes.table} min-content`} size="small" aria-label="a dense table">

                <TableHead>
                <TableRow style={{border: "2px solid black"}}>
                    <TableCell>Folder</TableCell>
                    <TableCell align="center">document Name</TableCell>
                    <TableCell align="center">document Id</TableCell>
                    {/* <TableCell align="right">document Version Id</TableCell> */}
                    <TableCell align="center">ApprovalCycleInfo</TableCell>
                    <TableCell align="center">View</TableCell>
                    <TableCell align="center">Delete</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {value.length > 0 && value.map(function(row, index){
                        let webCenterUrl = process.env.REACT_APP_WEBCENTER_VIEW_FILE_URL;
                        let projectId = row.projectId;
                        let documentName = row.documentName;
                        let username = process.env.REACT_APP_WEBCENTER_USERNAME;
                        let password = process.env.REACT_APP_WEBCENTER_PASSWORD;
                        let fullUrl = `${webCenterUrl}?username=${username}&password=${password}&projectid=${projectId}&documentname=${documentName}&showuser=${true}`;    
                        let deleteUrl = "";
                        let returnAnnotationCallbackUrl = ""
                        let returnApprovalCallbackUrl = ""
                        let rejectcallbackurl = ""
                        let folderId = null;
                        {
                            if( (row.folderName === process.env.REACT_APP_FOLDERID_3D_APPROVE || row.folderName === process.env.REACT_APP_FOLDERID_SOFT_APPROVE ) && value.length > 1) {
                            
                                returnAnnotationCallbackUrl  = `${process.env.REACT_APP_API_URL}/orderfiles/annotationcallbackurl/${orderId}/${row.documentName}`
                                returnApprovalCallbackUrl = `${process.env.REACT_APP_API_URL}/orderfiles/responsecallbackurl/${orderId}/${row.documentName}/${row.folderName}/1/1` // :orderId/:filename/:foldername/:approve/:isAdmin
                                rejectcallbackurl = `${process.env.REACT_APP_API_URL}/orderfiles/responsecallbackurl/${orderId}/${row.documentName}/${row.folderName}/0/1`
                                if(row.folderName === process.env.REACT_APP_FOLDERID_3D_APPROVE){
                                    fullUrl += `&approvalcallbackurl=${returnApprovalCallbackUrl}&closesessionafter=1`
                                }else{
                                    fullUrl += `&annotationcallbackurl=${returnAnnotationCallbackUrl}&approvalcallbackurl=${returnApprovalCallbackUrl}&returnurl=${returnApprovalCallbackUrl}&rejectcallbackurl=${rejectcallbackurl}`
                                }    
                            }
                        }
                        return <TableRow key={index} >
                            <TableCell component="th" scope="row">
                            {row.folderName}
                            </TableCell>
                            <TableCell align="center">{row.documentName}</TableCell>
                            <TableCell align="center">{row.documentId}</TableCell>
                            {/* <TableCell align="right">{row.documentVersionId}</TableCell> */}
                            <TableCell align="center">
                            {(row.approvalInfoString) ? 
                                (row.approvalInfoString === "Approved") ? <DoneIcon style={{ color: green[500] }} /> : (row.approvalInfoString === 'Pending') ? <HelpIcon style={{ color: orange[500] }} /> :<CancelIcon  style={{ color: red[500] }}/>
                                : row.approvalCycleInfo
                            }
                            </TableCell>
                            <TableCell align="center">
                            {(row.viewable) ? 
                                    <Link href={fullUrl} target="_blank" color="inherit">
                                        {row.documentName}
                                    </Link>
                                : "Not access"
                            }
                            </TableCell>
                            <TableCell align="center">
                            {(row.folderName === "3D Approve" || row.folderName === "Soft Approve") ? 
                                    <Button 
                                        label="Delete"
                                        color="red"
                                        onClick={(e) => {deleteFile(row.documentVersionId, projectId)}}
                                    > Delete </Button>
                                : "Not access"
                            }
                            

                            </TableCell>
                            
                        </TableRow>
                    })
                }
                </TableBody>
            </Table>

        </>
    )
}

export default ViewFiles