import React from "react";
import './style.css';
import {
  FormTab,
  TabbedForm,
  ShowButton,
  Show,
  Tab,
  List,
  Datagrid,
  ImageField,
  Edit,
  Create,
  Filter,
  TextField,
  EditButton,
  DisabledInput,
  TextInput,
  required,
  minLength
} from "react-admin";
const validateTitle = [required(), minLength(2)];
const validateField = [required()];
const MetaFilter = props => (
  <Filter {...props}>
    <TextInput label="Url" source="url" alwaysOn />
    <TextInput label="Title" source="title" />
    <TextInput label="Description" source="description" />
  </Filter>
);
const MetaTitle = ({ record }) => {
  return <span>Meta {record ? `"${record.title}"` : ""}</span>;
};
export const MetasList = props => {
  let list = (
    <List filters={<MetaFilter />} {...props}>
      <Datagrid className="meta-list">
        <TextField source="id" />
        <TextField source="title" />
        <TextField source="description" />
        <TextField source="route" label="Url" />
        <EditButton basePath="/metas" />
        <ShowButton basePath="/metas" />
      </Datagrid>
    </List>
  );
  return list;
};
export const MetasShow = props => (
  <Show title={<MetaTitle />} {...props}>
    <TabbedForm className='meta-show ra-field-bordered flexible-card-span-child column-3'>
      <Tab label="summary">
        <TextField source="id" />
        <TextField source="title" />
        <TextField source="description" />
        <TextField source="route" label="Url" />
        {/* <ImageField source="image" title="title" /> */}
      </Tab>
    </TabbedForm>
  </Show>
);

export const MetasEdit = props => {
  return (
    <Edit title={<MetaTitle />} {...props}>
      <TabbedForm className='meta-edit ra-input-bordered flexible-card-span-child column-4'>
        <FormTab label="summary">
          <TextField source="id" />
          <TextInput source="title" validate={validateTitle} />
          <TextInput source="description" validate={validateField}/>
          <TextInput source="route" label="Url" validate={validateField}/>
          {/* <BooleanInput label="Active" source="active" /> */}
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export const MetasCreate = props => (
  <Create {...props}>
    <TabbedForm className='meta-create ra-input-bordered flexible-card-span-child column-4'>
      <FormTab label="summary">
        <DisabledInput source="id" />
        <TextInput source="title" validate={validateTitle} />
        <TextInput source="description" validate={validateField}/>
        <TextInput source="route" label="Url"  validate={validateField}/>
      </FormTab>
    </TabbedForm>
  </Create>
);
