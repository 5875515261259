import React, { Component } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
 
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class CalculateLogic extends Component {

    constructor(props){

        super(props);
        this.state = {
            calculationLogics:props.calculationLogics,
            useStyles:props.useStyles,
            calculateLogicId:props.calculateLogicId
        }
        this.handleChange = this.handleChange.bind(this);
    }
    componentWillReceiveProps(props){
        const {calculationLogics,calculateLogicId}  = props;
        this.setState({calculationLogics,calculateLogicId});
    }
    handleChange(event){

        this.setState({calculateLogicId:event.target.value});
        this.props.addCalcLogic(event.target.value)
    }
    render(){
        const {calculationLogics,calculateLogicId,useStyles} = this.state;
        if(!calculationLogics || calculationLogics.length === 0) return ""
        return (   
            <div>
                <FormControl style={useStyles.formControl}>
                <InputLabel htmlFor="select-multiple-checkbox">Calculate Logic</InputLabel>
                    <Select
                    value={calculateLogicId}
                    onChange={this.handleChange}
                    MenuProps={MenuProps}
                    >
                    {calculationLogics.map(logic => (
                       
                        <MenuItem key={logic.id} value={logic.id}>
                            <ListItemText primary={logic.mathOperation} />
                        </MenuItem>
                    ))}
                    </Select>
                </FormControl>
            </div>
        )
    }
}
export default CalculateLogic