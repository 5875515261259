// in addUploadFeature.js
/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      console.log("reader", reader);
      resolve(reader.result);
    };
    reader.onerror = reject;
  });

const imageconvertFileToBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);

    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

/**
 * For posts update only, convert uploaded image in base 64 and attach it to
 * the `picture` sent property, with `src` and `title` attributes.
 */
const addUploadFeature = requestHandler => (type, resource, params) => {
  if (type === "GET_ONE" && (resource === "admingroups"|| resource === "roles")) {
    return requestHandler(type, resource, params)
            .then(response=>{
              if(response.data && response.data.permissions && response.data.permissions.length == 1){
                response.data.permissions.push(null)
              }
              return response;           
            })
  }
  if ((type === "UPDATE" || type === "CREATE") && resource === "banners") {
    // notice that following condition can be true only when `<ImageInput source="pictures" />` component has parameter `multiple={true}`
    // if parameter `multiple` is false, then data.pictures is not an array, but single object
 
    if (params.data.file) {
      // only freshly dropped pictures are instance of File
      //const formerPictures = !params.data.file.rawFile instanceof File;
      const newPictures = params.data.file.rawFile;
      if (typeof params.data.file == "object") {
        return convertFileToBase64(newPictures)
          .then(base64Picture => {
            const returnData = {
              src: base64Picture,
              title: `${newPictures.name}`
            };
            return returnData;
          })
          .then(transformedNewPictures =>
            requestHandler(type, resource, {
              ...params,
              data: {
                ...params.data,
                files: transformedNewPictures
              }
            })
          )
          .catch(error => {
            console.log(error);
          });
      } else {
        delete params.data["file"];
        requestHandler(type, resource, {
          ...params,
          data: {
            ...params.data
          }
        });
      }
    }
  }

  if ((type === "UPDATE" || type === "CREATE") && resource === "products") {
    // notice that following condition can be true only when `<ImageInput source="pictures" />` component has parameter `multiple={true}`
    // if parameter `multiple` is false, then data.pictures is not an array, but single object
    if (params.data.pictures && params.data.pictures.length) {
      // only freshly dropped pictures are instance of File
      const formerPictures = params.data.pictures.filter(
        p => !(p.rawFile instanceof File)
      );
      const newPictures = params.data.pictures.filter(
        p => p.rawFile instanceof File
      );

      return Promise.all(newPictures.map(imageconvertFileToBase64))
        .then(base64Pictures =>
          base64Pictures.map((picture64, index) => ({
            src: picture64,
            title: `${newPictures[index].title}`,
            folder:'products'
          }))
        )
        .then(transformedNewPictures =>
          requestHandler(type, resource, {
            ...params,
            data: {
              ...params.data,
              pictures: [...transformedNewPictures, ...formerPictures]
            }
          })
        );
    }
  }

    if ((type === "UPDATE" || type === "CREATE") && resource === "categories") {
        // notice that following condition can be true only when `<ImageInput source="pictures" />` component has parameter `multiple={true}`
        // if parameter `multiple` is false, then data.pictures is not an array, but single object
        if (params.data.pictures && params.data.pictures.length) {
            // only freshly dropped pictures are instance of File
            const formerPictures = params.data.pictures.filter(
                p => !(p.rawFile instanceof File)
            );
            const newPictures = params.data.pictures.filter(
                p => p.rawFile instanceof File
            );

            return Promise.all(newPictures.map(imageconvertFileToBase64))
                .then(base64Pictures =>
                    base64Pictures.map((picture64, index) => ({
                        src: picture64,
                        title: `${newPictures[index].title}`,
                        folder:'categories'
                    }))
                )
                .then(transformedNewPictures =>
                    requestHandler(type, resource, {
                        ...params,
                        data: {
                            ...params.data,
                            pictures: [...transformedNewPictures, ...formerPictures]
                        }
                    })
                );
        }
    }

  if ((type === "UPDATE" || type === "CREATE") && resource === "users") {
    // notice that following condition can be true only when `<ImageInput source="pictures" />` component has parameter `multiple={true}`
    // if parameter `multiple` is false, then data.pictures is not an array, but single object
    console.log(params.data.files);
    if (params.data.files) {
      const formerPictures = params.data.files.filter(
        p => !(p.rawFile instanceof File)
      );
      const newPictures = params.data.files.filter(
        p => p.rawFile instanceof File
      );
      return Promise.all(newPictures.map(imageconvertFileToBase64))
        .then(base64Pictures =>
          base64Pictures.map((picture64, index) => ({
            src: picture64,
            title: `${newPictures[index].title}`
          }))
        )
        .then(transformedNewPictures =>
          requestHandler(type, resource, {
            ...params,
            data: {
              ...params.data,
              files: [...transformedNewPictures, ...formerPictures]
            }
          })
        );
    }
  }
  // for other request types and resources, fall back to the default request handler
  return requestHandler(type, resource, params);
};

export default addUploadFeature;
