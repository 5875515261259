import React from 'react';
import Radio from '@material-ui/core/Radio';
import { makeStyles } from '@material-ui/core/styles';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import axios from "axios";
import authHeader from "../_helpers/authHeader";
const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: "100%"
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
export default function FormControlLabelPosition(props) {
  const classes = useStyles();
  const { value , onChangeValue, weight, country, city } = props;
  const [sendData, setSendData] = React.useState(true);
  const [shippingmethods, setShippingmethods] = React.useState();
 
  React.useEffect( () => {
        const formData = new FormData();
        formData.append('weight',weight);
        formData.append('city',city);
        formData.append('country',country);
        axios.post(`${process.env.REACT_APP_API_URL}/getshippingmethods`,formData,
        {
            headers:{Authorization:authHeader().Authorization},
        }).then((response) =>{
            if(response.status === 200 && response.data.length > 0){
                setShippingmethods(response.data)
                setSendData(false);
            }
        });
  }, [props.city, props.country, props.weight ]);

  
  return (
    <FormControl disabled={props.disabled} component="fieldset" className={classes.formControl}>
      <FormLabel component="legend">Shipping Methods</FormLabel>
      <RadioGroup aria-label="position" name="position" value={value} onChange={onChangeValue} row>
         {shippingmethods ? shippingmethods.map(function(method) {
            return (
                <FormControlLabel
                    key={method.id}
                    name = {method.price}
                    value={method.id}
                    control={<Radio color="primary" checked={(value == method.id) ? true : false} />}
                    label={`${method.name} - Price ${method.price}`}
                    labelPlacement={method.name}
                />
            );
          }) : "" }
        
      </RadioGroup>
    </FormControl>
  );
}


