// in posts.js
import React from 'react';
import Loadable from 'react-loadable'



function Loading() {
    return <div>Loading...</div>;
}

export const HomeProductsList = Loadable({
    loader: () => import('./View'),
    loading: Loading,
});